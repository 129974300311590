<template>
  <div class="announce">
    <div class="title">
      <img src="../../assets/news.png" alt="" />
      <div class="swiper-list">
        <div
          class="gg-list"
          :class="p.extraClass"
          v-for="(p, ggIndex) in content.list[0].list"
          :key="'' + ' ' + 'gg_' + p.id + ggIndex"
          @click="$parent.onAnnounce(p.id, 1)"
        >
          {{ p.title }}
        </div>
      </div>
    </div>
    <div class="content">
      欢迎来到美美吴江，你是本站的第{{ $rtm.store.visitCount }}位到访者
    </div>
  </div>
</template>

<script>
var timers = [];
export default {
  name: "IndexAnnounce",
  components: {},
  props: {
    content: {
      type: Object,
      default: function () {
        return {};
      },
    },
    logined: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
  },
  data: function () {
    return { webItem: {} };
  },
  created() {
    this.reload();
  },
  methods: {
    reload() {
      let thiz = this;
      // this.content.list.forEach((k) => {
      //   if (k.title == "品牌服务") {
      //     thiz.pinpaiInfo = k;
      //   } else if (k.title == "视觉动态") {
      //     thiz.dongtaiInfo = k;
      //     if (thiz.dongtaiInfo.list[0].id) {
      //       thiz.gonggaoNoImg = RD.notice()
      //         .id(thiz.dongtaiInfo.list[0].id)
      //         .noticeImage_URL();
      //     }
      //   } else {
      //     if (k.list && k.list.length < 4) {
      //       JSON.parse(JSON.stringify(k.list)).forEach((item) => {
      //         k.list.push(item);
      //       });
      //     }
      setTimeout(() => {
        thiz.setExtraPos();
      }, 1000);
      clearInterval(timers);
      timers = setInterval(() => {
        (() => {
          thiz.setExtraPos();
        })();
      }, 5000);
      //   }
      // });
      this.webItem = this.content;
    },
    // 公告图片切换
    onChangeImg(id) {
      var thiz = this;
      thiz.noticeId = id;
      thiz.gonggaoImg = RD.notice().id(id).noticeImage_URL();
    },
    setExtraPos() {
      var thiz = this;
      var targetList = this.content.list[0].list;
      var currentIndex = -1;
      if (targetList.length != 0) {
        targetList.forEach((item, index) => {
          if (item.extraClass == "active") {
            currentIndex = index;
          }
        });
        if (currentIndex == -1) {
          currentIndex = 0;
        }
        targetList.forEach((item, index) => {
          thiz.$set(item, "extraClass", "");
        });
        targetList[
          (currentIndex + targetList.length) % targetList.length
        ].extraClass = "active-next";
        targetList[
          (currentIndex + targetList.length + 1) % targetList.length
        ].extraClass = "active";
      }
    },
  },
};
</script>

<style lang="less" scoped>
@media all and (max-width: 800px) {
  .announce {
    display: flex;
    width: 730px;
    margin: 0 auto;
    height: 30px;
    background-color: #f7f8fa;
    position: relative;
    .title {
      width: 730px;
      font-size: 1.4rem;
      color: #000;
      line-height: 30px;
      text-align: center;
      display: flex;
      overflow: hidden;
      img {
        object-fit: contain;
        width: 20px;
        height: 1.8rem;
        margin-top: 8px;
      }
      .swiper-list {
        width: 78rem;
        display: flex;
        margin-left: 1rem;
        position: relative;
        .gg-list {
          height: 30px;
          line-height: 30px;
          text-align: left;
          font-size: 15px;
          position: absolute;
          width: 100%;
          top: -4.8rem;
          cursor: pointer;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .gg-list.active {
          top: 0rem;
          display: block;
          transition: all 600ms ease-in-out;
        }
        .gg-list.active-next {
          top: 4.8rem;
          display: block;
          transition: all 600ms ease-in-out;
        }
      }
    }
    .content {
      position: absolute;
      top: 8px;
      right: 0px;
      font-size: 15px;
      line-height: 2.8rem;
    }
  }
}
@media all and (min-width: 801px) {
  .announce {
    display: flex;
    width: 120rem;
    margin: 0 auto;
    height: 2.8rem;
    background-color: #f7f8fa;
    position: relative;
    .title {
      width: 80rem;
      font-size: 1.4rem;
      color: #000;
      line-height: 2.8rem;
      text-align: center;
      display: flex;
      overflow: hidden;
      img {
        object-fit: contain;
        width: 1.5rem;
        height: 1.8rem;
        margin-top: 0.3rem;
      }
      .swiper-list {
        width: 78rem;
        display: flex;
        margin-left: 1rem;
        position: relative;
        .gg-list {
          height: 2.8rem;
          line-height: 2.8rem;
          text-align: left;
          font-size: 1.5rem;
          position: absolute;
          width: 100%;
          top: -4.8rem;
          cursor: pointer;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .gg-list.active {
          top: 0rem;
          display: block;
          transition: all 600ms ease-in-out;
        }
        .gg-list.active-next {
          top: 4.8rem;
          display: block;
          transition: all 600ms ease-in-out;
        }
      }
    }
    .content {
      position: absolute;
      right: 0px;
      font-size: 1.4rem;
      line-height: 2.8rem;
    }
  }
}
</style>