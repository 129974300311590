<template>
  <div class="np">
    <div class="np-content">
      <div class="np-title">
        <div class="name" @click="onNewPosts(content.title)">
          {{ content.title }}
        </div>
        <div class="underline"></div>
        <div class="more" @click="onNewPosts(content.title)">(更多)</div>
      </div>
      <div class="np-list" v-if="content.npList">
        <div
          class="np-detail"
          v-for="(k, npindex) in content.npList"
          :key="k.id + 'np' + npindex"
          :class="{
            'pre-2': k.loc == -2,
            'pre-1': k.loc == -1,
            active: k.loc == 0,
            'next-1': k.loc == 1,
            'next-2': k.loc == 2,
          }"
        >
          <div
            class="img"
            :style="{ backgroundImage: `url(` + k.imgURL + `)` }"
          >
            <a target="_blank" :href="'post.html?id=' + k.id" />
          </div>
          <div class="text">{{ k.title }}</div>
        </div>
      </div>
      <div class="np-button np-left el-icon-back" @click="npGoLeft()"></div>
      <div class="np-button np-right el-icon-right" @click="npGoRight()"></div>
    </div>
  </div>
</template>

<script>
var timers = [];
import RD from "@/api/RD";

export default {
  name: "IndexNp",
  components: {},
  props: {
    content: {
      type: Object,
      default: function () {
        return {};
      },
    },
    logined: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
  },
  data: function () {
    return {};
  },
  created() {
    this.reload();
  },
  methods: {
    reload() {
      setTimeout(() => {
        var query = {};
        var page = 1;
        query.storeIds = [1];
        var nowTime = new Date();
        query.uploadTo =
          nowTime.getFullYear() +
          "-" +
          ("" + (nowTime.getMonth() + 1)).padStart(1, "0") +
          "-" +
          ("" + nowTime.getDate()).padStart(1, "0");
        var after = new Date(nowTime - 100 * 24 * 3600 * 1000);
        query.uploadFrom =
          after.getFullYear() +
          "-" +
          ("" + (after.getMonth() + 1)).padStart(1, "0") +
          "-" +
          ("" + after.getDate()).padStart(1, "0");
        RD.pure()
          .query()
          .query(query, page, 20)
          .then((items) => {
            items.list.forEach((i) => {
              if (i.frontPicId) {
                i.imgURL = RD.pic().id(i.frontPicId).mImage_URL();
              }
            });
            this.$set(this.content, "npList", items.list);
            this.$set(this.content, "npIndex", 0);
            this.npListProcess(this.content);
          });
      }, 1000);

      timers = setInterval(() => {
        this.npGoLeft();
      }, 3000);
    },
    /**
     * 处理nplist的位置函数，注入对应code入item的loc
     */
    npListProcess(content) {
      //npList npIndex
      let list = JSON.parse(JSON.stringify(content.npList));
      let index = content.npIndex;
      list.forEach((item) => {
        item.loc = undefined;
      });
      list[index].loc = 0;
      list[
        index + 1 >= list.length ? index + 1 - list.length : index + 1
      ].loc = 1;
      list[
        index + 2 >= list.length ? index + 2 - list.length : index + 2
      ].loc = 2;
      list[index - 1 < 0 ? index - 1 + list.length : index - 1].loc = -1;
      list[index - 2 < 0 ? index - 2 + list.length : index - 2].loc = -2;
      content.npList = list;
    },
    // 最新图片的左移
    npGoRight() {
      this.content.npIndex--;
      if (this.content.npIndex < 0) {
        this.content.npIndex = this.content.npList.length - 1;
      }
      this.npListProcess(this.content);
    },
    npGoLeft() {
      this.content.npIndex++;
      if (this.content.npIndex >= this.content.npList.length) {
        this.content.npIndex = 0;
      }
      this.npListProcess(this.content);
    },
    // np的更多
    onNewPosts() {
      this.$router.push({
        path: "/newPosts.html",
      });
    },
  },
};
</script>

<style lang="less" scoped>
@media all and (max-width: 800px) {
  .np {
    width: 750px;
    height: 255px;
    overflow: hidden;
    margin: 33.3px auto;
    position: relative;

    .np-content {
      overflow: hidden;
      width: 100%;
      height: 100%;
      margin: 0 auto;

      .np-title {
        position: absolute;
        top: 0px;
        left: 226px;
        width: 284px;
        height: 67px;
        z-index: 90;

        .name {
          height: 40px;
          line-height: 40px;
          cursor: pointer;
          font-size: 22px;
          width: 200px;
          margin: 0 auto;
        }

        .underline {
          position: absolute;
          left: 28%;
          top: 54px;
          width: 50%;
          height: 4px;
          background-color: #cf0000;
        }

        .more {
          position: absolute;
          left: 67%;
          top: 10px;
          height: 40px;
          width: 50px;
          font-size: 16px;
          cursor: pointer;
        }
      }

      .np-list {
        width: 750px;
        height: 150px;
        position: absolute;
        bottom: 0px;
        left: 0px;
        overflow: hidden;

        .np-detail {
          height: 116px;
          width: 222px;
          overflow: hidden;
          position: absolute;
          bottom: 750px;
          left: 222px;
          transition: all 300ms ease-in-out;

          .img {
            height: 100%;
            width: 100%;
            background: no-repeat 50% / cover;
            transition: all 300ms ease-in-out;
            position: relative;

            a {
              height: 100%;
              width: 100%;
              display: block;
            }
          }

          .text {
            position: absolute;
            bottom: 0px;
            font-size: 16px;
            text-align: center;
            height: 30px;
            line-height: 30px;
            width: calc(100% - 30px);
            padding: 0px 16px;
            background: rgba(0, 0, 0, 0.3);
            color: #fff;
            cursor: pointer;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            transition: all 300ms ease-in-out;
          }
        }

        .np-detail:hover {
          .img {
            transform: scale(1.05);
          }

          .text {
            font-size: 2rem;
            background: rgba(0, 0, 0, 0.4);
          }
        }

        .np-detail.active {
          bottom: 0px;
          left: 266px;
        }

        .np-detail.next-1 {
          bottom: 40px;
          left: 510px;
        }

        .np-detail.next-2 {
          bottom: 334px;
          left: 1001px;
        }

        .np-detail.pre-1 {
          bottom: 40px;
          left: 20px;
        }

        .np-detail.pre-2 {
          bottom: 334px;
          left: -333px;
        }
      }

      .np-left {
        left: 0px;
      }

      .np-right {
        right: 0px;
      }

      .np-button {
        position: absolute;
        bottom: -8px;
        height: 40px;
        width: 240px;
        line-height: 33px;
        font-size: 30px;
        color: rgb(255, 255, 255);
        // background-color: rgba(0, 0, 0, 0.1);
      }

      .el-icon-back,
      .el-icon-right {
        color: #ccc;
        font-size: 30px;
      }

      .np-button:hover {
        color: #cf0000;
        background-color: rgba(0, 0, 0, 0.1);
        cursor: pointer;
      }

      .np-button:active {
        color: #cf0000;
        font-size: 3.75rem;
        background-color: rgba(0, 0, 0, 0.1);
      }
    }

    .np-transform {
      height: 4.2rem;
      margin-top: 1.7rem;

      .np-left {
        width: 8.3rem;
        height: 4.2rem;
        cursor: pointer;
      }

      .el-icon-back {
        font-size: 3.33rem;
      }

      .el-icon-right {
        font-size: 3.33rem;
      }

      .np-right {
        width: 8.3rem;
        height: 4.2rem;
        cursor: pointer;
      }
    }
  }
}
@media all and (min-width: 801px) {
  .np {
    width: 120rem;
    height: 38rem;
    overflow: hidden;
    margin: 3.33rem auto;
    position: relative;

    .np-content {
      overflow: hidden;
      width: 120rem;
      height: 38rem;
      margin: 0 auto;
      position: relative;
      .np-title {
        position: absolute;
        top: 0px;
        left: 42.4rem;
        width: 33.4rem;
        height: 6.7rem;
        z-index: 90;

        .name {
          height: 5rem;
          line-height: 5rem;
          cursor: pointer;
          font-size: 2.2rem;
          width: 16.7rem;
          margin: 0 auto;
        }

        .underline {
          position: absolute;
          left: 35%;
          top: 54px;
          width: 47%;
          height: 0.42rem;
          background-color: #cf0000;
        }

        .more {
          position: absolute;
          left: 67%;
          top: 1.7rem;
          height: 6.25rem;
          width: 5rem;
          font-size: 1.7rem;
          cursor: pointer;
        }
      }

      .np-list {
        width: 120rem;
        height: 25rem;
        position: absolute;
        bottom: 0px;
        left: 0px;

        .np-detail {
          height: 19.16rem;
          width: 28rem;
          overflow: hidden;
          position: absolute;
          bottom: 75rem;
          left: 33.4rem;
          transition: all 300ms ease-in-out;

          .img {
            height: 100%;
            width: 100%;
            background: no-repeat 50% / cover;
            transition: all 300ms ease-in-out;
            position: relative;

            a {
              height: 100%;
              width: 100%;
              display: block;
            }
          }

          .text {
            position: absolute;
            bottom: 0px;
            font-size: 1.7rem;
            text-align: center;
            height: 3.33rem;
            line-height: 3.33rem;
            width: calc(100% - 3.33rem);
            padding: 0px 1.7rem;
            background: rgba(0, 0, 0, 0.3);
            color: #fff;
            cursor: pointer;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            transition: all 300ms ease-in-out;
          }
        }

        .np-detail:hover {
          .img {
            transform: scale(1.05);
          }

          .text {
            font-size: 2rem;
            background: rgba(0, 0, 0, 0.4);
          }
        }

        .np-detail.active {
          bottom: 80px;
          left: 30.5rem;
        }

        .np-detail.next-1 {
          bottom: 8rem;
          left: 61rem;
        }

        .np-detail.next-2 {
          bottom: 2rem;
          left: 92rem;
        }

        .np-detail.pre-1 {
          bottom: 2rem;
          left: 0px;
        }

        .np-detail.pre-2 {
          bottom: 6rem;
          left: -30.7rem;
        }
      }

      .np-left {
        left: 0px;
      }

      .np-right {
        right: 0px;
      }

      .np-button {
        position: absolute;
        bottom: 30px;
        height: 4.2rem;
        width: 12.6rem;
        line-height: 4.2rem;
        font-size: 3.33rem;
        color: rgb(255, 255, 255);
        // background-color: rgba(0, 0, 0, 0.1);
      }

      .el-icon-back,
      .el-icon-right {
        color: #ccc;
        font-size: 4rem;
      }

      .np-button:hover {
        color: #cf0000;
        background-color: rgba(0, 0, 0, 0.1);
        cursor: pointer;
      }

      .np-button:active {
        color: #cf0000;
        font-size: 3.75rem;
        background-color: rgba(0, 0, 0, 0.1);
      }
      .np-left {
        position: absolute;
        left: 45rem;
        bottom: 0rem;
      }
      .np-right {
        position: absolute;
        right: 40rem;
        bottom: 0rem;
      }
    }

    .np-transform {
      height: 4.2rem;
      margin-top: 1.7rem;

      .np-left {
        width: 8.3rem;
        height: 4.2rem;
        cursor: pointer;
      }

      .el-icon-back {
        font-size: 3.33rem;
      }

      .el-icon-right {
        font-size: 3.33rem;
      }

      .np-right {
        width: 8.3rem;
        height: 4.2rem;
        cursor: pointer;
      }
    }
  }
}
</style>

