<template>
  <div class="web-item" :class="'model_' + content.model">
    <div class="web-item-header">
      <div class="web-item-title" @click="onMoreFT(webItem)">
        {{ webItem.title }}
      </div>
      <div class="item-subtitle">{{ content.subTitle }}</div>
      <div class="web-item-more" @click="onMoreFT(webItem)">更多>></div>
    </div>
    <div
      class="ft-model-m_autoSwipe"
      v-if="webItem.model == 'm_autoSwipe'"
      @mouseenter="stopTimer()"
      @mouseleave="restartTimer()"
    >
      <template v-for="(pic, contentIndex) in webItem.list">
        <div
          :key="contentIndex + 'FT' + pic.id"
          class="post-con"
          :style="{ backgroundImage: `url(` + pic.imgURL + `)` }"
          v-show="
            swipeFilter(contentIndex, webItem.list.length, swipeIndex, 4, 1)
          "
          :class="
            'pos-' +
            swipePosition(contentIndex, swipeIndex, webItem.list.length, 6)
          "
          @click="onLookPost(pic.postId)"
        ></div>
      </template>
      <div class="text">
        <div
          v-for="(pic, navIndex) in webItem.list"
          :key="'ft_' + pic.id + '_nav' + '_' + navIndex"
          class="nav-pic"
          :class="{ active: content.picIndex == navIndex }"
        ></div>
      </div>
    </div>

    <!-- 固定栏目其它 -->
    <div :class="'ft-model-' + webItem.model" v-else>
      <div
        class="post-con"
        v-for="post in webItem.list"
        :key="'ft' + post.postId"
        @click="onLookPost(post.postId)"
      >
        <div class="post-img">
          <div
            class="img"
            :style="{ backgroundImage: `url(` + post.imgURL + `)` }"
          ></div>
        </div>
        <div class="post-textm22" v-if="webItem.model == 'm_22'"></div>
        <div class="post-text" v-else>
          <!-- {{ post.title }} -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
var swiperTimers = [];
import RD from "@/api/RD";

export default {
  name: "IndexFt",
  components: {},
  props: {
    content: {
      type: Object,
      default: function () {
        return {};
      },
    },
    logined: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
  },
  data: function () {
    return {
      webItem: {},
      swipeIndex: 0, // 轮播的Index
      swipeMoving: false,
    };
  },
  created() {
    this.reload();
  },
  destroyed() {
    clearInterval(swiperTimers);
  },
  methods: {
    reload() {
      console.log("ft", this.content);
      this.content.list.forEach((i) => {
        if (i.postId) {
          i.imgURL = RD.pic().id(i.frontPicId).sImage_URL();
        }
        if (this.content.model == "3s") {
          i.imgURL = RD.pic().id(i.frontPicId).tImage_URL();
        }
      });
      this.webItem = this.content;

      if (this.content.model == "m_autoSwipe") {
        if (swiperTimers) {
          clearInterval(swiperTimers);
        }
        swiperTimers = setInterval(() => {
          this.autoSwiper();
        }, 3000);
      }
      this.$nextTick(() => {
        this.swipeMoving = false;
      });
    },
    autoSwiper() {
      if (this.swipeMoving) {
        return;
      }
      this.swipeMoving = true;
      this.swipeIndex += 1;
      setTimeout(() => {
        this.swipeMoving = false;
      }, 500);
    },
    scrollTo(navIndex) {
      this.content.picIndex = navIndex;
    },
    nextsky() {
      var thiz = this;
      thiz.content.picIndex++;
      if (thiz.content.picIndex >= thiz.content.list.length) {
        thiz.content.picIndex = 0;
      }
    },
    stopTimer() {
      clearInterval(swiperTimers);
    },
    restartTimer() {
      if (swiperTimers) {
        clearInterval(swiperTimers);
      }
      swiperTimers = setInterval(() => {
        this.autoSwiper();
      }, 2000);
    },
    // 查看稿件
    onLookPost(postId) {
      this.$router.push({
        path: "/post_" + postId + ".html",
      });
    },
    // 更多的栏目稿件
    onMoreFT(item) {
      console.log("ms", item);

      this.$router.push({
        path: "/fixTopic.html",
        query: { id: item.id },
      });
    },
    /**
     * swipe filter utils, return if the target index is show or not
     * @Param targetIndex item index to test
     * @Param length array length
     * @Param swipeIndex swipePosition to test
     * @Param forwardGap how many item in the array larger than swipeIndex will be marked as valid
     * @Param backwardGap how many item in the array smaller the swipeIndex will be marked as valid
     */
    swipeFilter(t, l, s, f, b) {
      return !((((t - s) % l) + l) % l > f && (((s - t) % l) + l) % l > b);
    },
    /**
     * return the position index
     * position when swipeIndex == targetIndex return 0
     * forward return  1 2 3
     * backword return total -1 total -2 total -3
     * @Param targetIndex
     * @Param swipeIndex
     * @Param total length of array
     * @Param length of items show
     */
    swipePosition(t, s, l, g) {
      return Math.abs((t - (((s % l) + l) % l) + l) % l) <=
        Math.abs(((((s % l) + l) % l) - t + l) % l)
        ? ((((t - s) % l) + l) % l) % g
        : t > ((s % l) + l) % l
        ? (((-(((s % l) + l) % l) - l + t) % g) + g) % g
        : (((-(((s % l) + l) % l) + t) % g) + g) % g;
    },
  },
};
</script>

<style lang="less" scoped>
// .camera-icon {
//   background: no-repeat 50% / contain url(../../assets/camera.png);
// }
@media all and (max-width: 800px) {
  .ft-con {
    width: 750px;
    margin: 1.7rem auto;
    position: relative;
    .web-item {
      margin-top: 20px;
      .web-item-header {
        position: relative;
        height: 50px;
        line-height: 50px;
        margin-top: 10px;
        .web-item-title {
          height: 40px;
          line-height: 40px;
          font-size: 24px;
          margin-left: 5px;
        }
        .item-subtitle {
          line-height: 40px;
          margin-left: 10px;
          font-size: 20px;
          color: #aaaaaa;
          vertical-align: bottom;
          font-family: "Microsoft Yahei", "Simsun";
        }
        .web-item-more {
          position: absolute;
          right: 5px;
          top: 5px;
          font-size: 15px;
        }
      }
    }

    .post-con:hover {
      .post-img {
        transform: scale(1.05);
      }

      .post-text {
        background-color: rgba(0, 0, 0, 0.4);
        width: 92%;
      }
    }

    .post-img {
      width: 100%;
      height: 100%;
      background: no-repeat 50% / cover;
      transition: all 500ms ease-in-out;
      cursor: pointer;
    }

    .post-text {
      position: absolute;
      bottom: 0.66rem;
      left: 50%;
      transform: translate(-50%);
      width: 85%;
      border-radius: 0.42rem;
      font-size: 1.3rem;
      text-align: center;
      height: 3.9rem;
      line-height: 3.9rem;
      // background-color: rgba(0, 0, 0, 0.3);
      color: #fff;
      transition: all 500ms ease-in-out;
    }

    .ft-model-3s {
      height: 79rem;
      position: relative;

      .pic {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        opacity: 0;
        background: no-repeat 50% / cover;
        transition: all 500ms ease-in-out;
        cursor: pointer;
        z-index: 100;
      }

      .pic.active {
        opacity: 1;
        z-index: 200;
      }

      .text {
        display: flex;
        margin: 1.25rem auto;
        position: absolute;
        bottom: 0px;
        width: 100%;
        justify-content: center;
        z-index: 300;

        .nav-pic {
          height: 1.25rem;
          width: 1.25rem;
          border-radius: 0.66rem;
          border: 1px solid rgba(255, 255, 255, 0.4);
          margin-right: 0.85rem;
          background: rgba(0, 0, 0, 0.5);
          transition: all 500ms ease-in-out;
          cursor: pointer;
        }

        .nav-pic.active {
          background-color: rgb(23, 47, 184);
          width: 3.75rem;
        }
      }
    }

    .ft-model-111 {
      height: 41.5rem;
      position: relative;
      overflow: hidden;
      width: 120rem;
      margin: 0 auto;

      display: flex;
      justify-content: space-between;

      .post-con {
        width: 39.5rem;
        height: 100%;
        position: relative;
        overflow: hidden;
      }

      .post-con:nth-child(1n + 4) {
        display: none;
      }
    }

    .ft-model-4s {
      height: 20rem;
      position: relative;
      overflow: hidden;
      width: 750px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;

      .post-con {
        width: 29.5rem;
        height: 20rem;
        position: relative;
        overflow: hidden;
      }

      .post-con:nth-child(1n + 5) {
        display: none;
      }
    }

    .ft-model-12 {
      height: 51rem;
      position: relative;
      overflow: hidden;
      width: 750px;
      margin: 0 auto;

      .post-con {
        position: absolute;
        overflow: hidden;
      }

      .post-con:nth-child(1) {
        width: 85rem;
        height: 51rem;
        top: 0;
        left: 0;
      }

      .post-con:nth-child(2) {
        width: 34rem;
        height: 25rem;
        top: 0;
        right: 0;
      }

      .post-con:nth-child(3) {
        width: 34rem;
        height: 25rem;
        bottom: 0;
        right: 0;
      }

      .post-con:nth-child(1n + 4) {
        display: none;
      }
    }

    .ft-model-21 {
      height: 51rem;
      position: relative;
      overflow: hidden;
      width: 750px;
      margin: 0 auto;

      .post-con {
        position: absolute;
        overflow: hidden;
      }

      .post-con:nth-child(1) {
        width: 45rem;
        height: 25rem;
        top: 0;
        left: 0;
      }

      .post-con:nth-child(2) {
        width: 45rem;
        height: 25rem;
        bottom: 0;
        left: 0;
      }

      .post-con:nth-child(3) {
        width: 74rem;
        height: 51rem;
        top: 0;
        right: 0;
      }

      .post-con:nth-child(1n + 4) {
        display: none;
      }
    }

    .ft-model-333 {
      height: 42rem;
      position: relative;
      overflow: hidden;
      width: 750px;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 2rem;

      .post-con {
        min-width: 29.5rem;
        flex: 1;
        height: 20rem;
        position: relative;
        overflow: hidden;
      }

      .post-con:nth-child(1n + 7) {
        display: none;
      }
    }

    .ft-model-m_14 {
      height: 41rem;
      position: relative;
      overflow: hidden;
      width: 750px;
      margin: 0 auto;

      .post-con {
        position: absolute;
        overflow: hidden;
        .post-img {
          width: 100%;
          height: 100%;
          .img {
            width: 100%;
            height: 100%;
            background: no-repeat 50% / cover;
          }
        }
        .post-text {
          position: absolute;
          bottom: -4rem;
          left: 0rem;
          color: #000;
        }
      }

      .post-con:nth-child(1) {
        width: 62rem;
        height: 41rem;
        top: 0;
        left: 0;
      }

      .post-con:nth-child(2),
      .post-con:nth-child(3),
      .post-con:nth-child(4),
      .post-con:nth-child(5) {
        width: 28rem;
        height: 20rem;
      }

      .post-con:nth-child(2) {
        top: 0;
        right: 29rem;
      }

      .post-con:nth-child(3) {
        bottom: 0;
        right: 29rem;
      }

      .post-con:nth-child(4) {
        top: 0;
        right: 0;
      }

      .post-con:nth-child(5) {
        bottom: 0;
        right: 0;
      }

      .post-con:nth-child(1n + 6) {
        display: none;
      }

      .post-con:hover {
        .post-text {
          bottom: 0rem;
        }
      }
    }

    .ft-model-1111 {
      height: 40rem;
      position: relative;
      overflow: hidden;
      width: 120rem;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      gap: 2rem;

      .post-con {
        flex: 1;
        height: 40rem;
        position: relative;
        overflow: hidden;
      }

      .post-con:nth-child(1n + 5) {
        display: none;
      }
    }
    .ft-model-2222 {
      height: 40rem;
      position: relative;
      overflow: hidden;
      width: 120rem;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      gap: 2rem;

      .post-con {
        flex: 1;
        height: 40rem;
        position: relative;
        overflow: hidden;
      }

      .post-con:nth-child(1n + 5) {
        display: none;
      }
    }
    .ft-model-m_12 {
      height: 51rem;
      position: relative;
      overflow: hidden;
      width: 750px;
      margin: 0 auto;

      .post-con {
        position: absolute;
        overflow: hidden;
        .post-img {
          height: 85%;
          overflow: hidden;
          transition: all 500ms ease-in-out;
          .img {
            width: 100%;
            height: 100%;
            background: no-repeat 50% / cover;
            transition: all 500ms ease-in-out;
          }
        }
        .post-text {
          height: 20px;
          line-height: 20px;
          background-color: rgba(0, 0, 0, 0);
          color: #000;
        }
      }

      .post-con:nth-child(1) {
        width: 85rem;
        height: 294px;
        top: 0;
        left: 0;
        .post-img {
          height: 90%;
        }
      }

      .post-con:nth-child(2) {
        width: 34rem;
        height: 142px;
        top: 0;
        right: 0;
      }

      .post-con:nth-child(3) {
        width: 34rem;
        height: 142px;
        bottom: 25px;
        right: 0;
      }

      .post-con:nth-child(1n + 4) {
        display: none;
      }
      .post-con:hover {
        .img {
          transform: scale(1.05);
        }
      }
    }

    .ft-model-m_212 {
      overflow: hidden;
      width: 750px;
      margin: 0 auto;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;
      position: relative;
      .post-con {
        // position: absolute;
        .img {
          width: 100%;
          height: 100%;
          background: no-repeat 50% / cover;
          transition: all 500ms ease-in-out;
        }
        .post-text {
          width: 100%;
        }
      }

      .post-con:nth-child(1) {
        top: 0;
      }
      .post-con:nth-child(2) {
        top: 0;
        left: 0;
      }
      .post-con:nth-child(3) {
        bottom: 0;
        left: 0;
      }
      .post-con:nth-child(4) {
        top: 0;
        right: 20px;
      }
      .post-con:nth-child(5) {
        bottom: 0;
        right: 20px;
      }
      .post-con:nth-child(1n + 6) {
        display: none;
      }
    }

    .ft-model-m_212 {
      height: 44rem;
      .post-con {
        position: absolute;
        .post-text {
          height: 3rem;
          line-height: 3rem;
          font-size: 1.3rem;
          display: none;
        }
      }

      .post-con:nth-child(1) {
        width: 57rem;
        height: 44.4rem;
        top: 0;
        left: 31.5rem;
      }
      .post-con:nth-child(2),
      .post-con:nth-child(3),
      .post-con:nth-child(4),
      .post-con:nth-child(5) {
        width: 29.4rem;
        height: 20.7rem;
      }
      .post-con:nth-child(1n + 6) {
        display: none;
      }
    }
    .ft-model-m_22 {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      height: auto;
      overflow: hidden;
      width: 750px;
      margin: 0 auto;
      .post-con {
        width: 370px;
        height: 22rem;
        margin-bottom: 2rem;
        .post-img {
          height: 100%;
          width: 100%;
          background-size: cover;
          cursor: pointer;
          .img {
            width: 100%;
            height: 100%;
            background: no-repeat 50% / cover;
            transition: all 500ms ease-in-out;
          }
        }

        .post-text {
          font-size: 1.5rem;
          display: flex;

          .icon {
            display: inline-block;
            width: 4.5rem;
            height: 3.5rem;
            margin-left: 22.4rem;
          }

          .culture-t {
            cursor: pointer;
          }

          div {
            height: 4.2rem;
            line-height: 4.2rem;
          }
        }
      }
    }
  }
}
@media all and (min-width: 801px) {
  .web-item {
    width: 100%;
    margin: 0 auto;
    height: auto;
    .web-item-header {
      width: 120rem;
      text-align: left;
      position: relative;
      display: flex;
      margin: 30px auto 0;
      .web-item-title {
        text-align: left;
        color: #000;
        // width: 20rem;
      }
      .web-item-header-gap {
        position: absolute;
        background: #bfbfbf;
      }
    }
  }
  .ft-con {
    width: 120rem;
    margin: 0 auto;
    margin: 1.7rem auto;
    position: relative;

    .post-con:hover {
      // .post-img {
      //   transform: scale(1.05);
      // }

      .post-text {
        background-color: rgba(0, 0, 0, 0.4);
        width: 92%;
        font-size: 1.6rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .post-img {
      width: 100%;
      height: 100%;
      background: no-repeat 50% / cover;
      transition: all 500ms ease-in-out;
      cursor: pointer;
      .img {
        width: 100%;
        height: 100%;
        background: no-repeat 50% / cover;
      }
    }

    .post-text {
      // position: absolute;
      // bottom: -4rem;
      // left: 0;
      // transform: translate(-50%);
      width: 100%;
      border-radius: 0.42rem;
      font-size: 1.3rem;
      text-align: center;
      height: 3.9rem;
      line-height: 3.9rem;
      // background-color: rgba(0, 0, 0, 0.3);
      color: #fff;
      transition: all 500ms ease-in-out;
    }

    .ft-model-m_212 {
      overflow: hidden;
      width: 120rem;
      margin: 0 auto;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;
      position: relative;
      .post-con {
        // position: absolute;
        .post-text {
          width: 100%;
        }
      }

      .post-con:nth-child(1) {
        top: 0;
      }
      .post-con:nth-child(2) {
        top: 0;
        left: 0;
      }
      .post-con:nth-child(3) {
        bottom: 0;
        left: 0;
      }
      .post-con:nth-child(4) {
        top: 0;
        right: 0;
      }
      .post-con:nth-child(5) {
        bottom: 0;
        right: 0;
      }
      .post-con:nth-child(1n + 6) {
        display: none;
      }
    }

    .ft-model-m_22 {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      height: auto;
      overflow: hidden;
      width: 120rem;
      margin: 0 auto;
      .post-con {
        width: 58.8rem;
        height: 22rem;
        margin-bottom: 2rem;
        .post-img {
          height: 100%;
          width: 100%;
          background-size: cover;
          cursor: pointer;
        }

        .post-text {
          font-size: 1.5rem;
          display: flex;

          .icon {
            display: inline-block;
            width: 4.5rem;
            height: 3.5rem;
            margin-left: 22.4rem;
          }

          .culture-t {
            cursor: pointer;
          }

          div {
            height: 4.2rem;
            line-height: 4.2rem;
          }
        }
      }
    }

    .ft-model-m_gy {
      height: 66rem;
      margin: 3rem 0rem 2rem;
      position: relative;
      .post-con {
        position: absolute;
        .post-text {
          height: 3rem;
          line-height: 3rem;
          font-size: 1.3rem;
        }
      }

      .post-con:nth-child(1) {
        width: 28rem;
        height: 23rem;
        top: 0;
        left: 0rem;
      }
      .post-con:nth-child(2) {
        width: 28rem;
        height: 23rem;
        top: 0;
        left: 30rem;
      }
      .post-con:nth-child(3) {
        width: 58rem;
        height: 40rem;
        top: 0px;
        right: 0px;
      }
      .post-con:nth-child(4) {
        width: 58rem;
        height: 40rem;
        top: 26rem;
        left: 0px;
      }
      .post-con:nth-child(5) {
        width: 28rem;
        height: 23rem;
        right: 30rem;
        bottom: 0px;
      }
      .post-con:nth-child(6) {
        width: 28rem;
        height: 23rem;
        right: 0rem;
        bottom: 0px;
      }
      .post-con:nth-child(1n + 7) {
        display: none;
      }
    }
    .ft-model-m_3s {
      height: 79rem;
      position: relative;

      .pic {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        opacity: 0;
        background: no-repeat 50% / cover;
        transition: all 500ms ease-in-out;
        cursor: pointer;
        z-index: 100;
      }

      .pic.active {
        opacity: 1;
        z-index: 200;
      }

      .text {
        display: flex;
        margin: 1.25rem auto;
        position: absolute;
        bottom: 0px;
        width: 100%;
        justify-content: center;
        z-index: 300;

        .nav-pic {
          height: 1.25rem;
          width: 1.25rem;
          border-radius: 0.66rem;
          border: 1px solid rgba(255, 255, 255, 0.4);
          margin-right: 0.85rem;
          background: rgba(0, 0, 0, 0.5);
          transition: all 500ms ease-in-out;
          cursor: pointer;
        }

        .nav-pic.active {
          background-color: rgb(23, 47, 184);
          width: 3.75rem;
        }
      }
    }

    .ft-model-m_111 {
      height: 41.5rem;
      position: relative;
      overflow: hidden;
      width: 120rem;
      margin: 0 auto;

      display: flex;
      justify-content: space-between;

      .post-con {
        width: 39.5rem;
        height: 100%;
        position: relative;
        overflow: hidden;
      }

      .post-con:nth-child(1n + 4) {
        display: none;
      }
    }

    .ft-model-m_1111 {
      height: 44rem;
      position: relative;
      overflow: hidden;
      width: 120rem;
      margin: 0 auto;

      display: flex;
      justify-content: space-between;

      .post-con {
        width: 28rem;
        height: 100%;
        position: relative;
        overflow: hidden;
      }

      .post-con:nth-child(1n + 5) {
        display: none;
      }
    }

    .ft-model-m_4s {
      height: 20rem;
      position: relative;
      overflow: hidden;
      width: 120rem;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;

      .post-con {
        width: 29.5rem;
        height: 20rem;
        position: relative;
        overflow: hidden;
      }

      .post-con:nth-child(1n + 5) {
        display: none;
      }
    }

    .ft-model-m_12 {
      height: 51rem;
      position: relative;
      overflow: hidden;
      width: 120rem;
      margin: 0 auto;

      .post-con {
        position: absolute;
        overflow: hidden;
        .post-img {
          height: 90%;
          overflow: hidden;
          transition: all 500ms ease-in-out;
          .img {
            width: 100%;
            height: 100%;
            background: no-repeat 50% / cover;
            transition: all 500ms ease-in-out;
          }
        }
        .post-text {
          height: 3rem;
          line-height: 3rem;
          background-color: rgba(0, 0, 0, 0);
          color: #000;
        }
      }

      .post-con:nth-child(1) {
        width: 85rem;
        height: 51rem;
        top: 0;
        left: 0;
      }

      .post-con:nth-child(2) {
        width: 34rem;
        height: 23.5rem;
        top: 0;
        right: 0;
      }

      .post-con:nth-child(3) {
        width: 34rem;
        height: 24rem;
        bottom: 2.8rem;
        right: 0;
      }

      .post-con:nth-child(1n + 4) {
        display: none;
      }
      .post-con:hover {
        .img {
          transform: scale(1.05);
        }
      }
    }

    .ft-model-m_212 {
      height: 44rem;
      .post-con {
        position: absolute;
        .post-text {
          height: 3rem;
          line-height: 3rem;
          font-size: 1.3rem;
          display: none;
        }
      }

      .post-con:nth-child(1) {
        width: 57rem;
        height: 44.4rem;
        top: 0;
        left: 31.5rem;
      }
      .post-con:nth-child(2),
      .post-con:nth-child(3),
      .post-con:nth-child(4),
      .post-con:nth-child(5) {
        width: 29.4rem;
        height: 20.7rem;
      }
      .post-con:nth-child(1n + 6) {
        display: none;
      }
    }

    .ft-model-m_21 {
      height: 51rem;
      position: relative;
      overflow: hidden;
      width: 120rem;
      margin: 0 auto;

      .post-con {
        position: absolute;
        overflow: hidden;
      }

      .post-con:nth-child(1) {
        width: 45rem;
        height: 25rem;
        top: 0;
        left: 0;
      }

      .post-con:nth-child(2) {
        width: 45rem;
        height: 25rem;
        bottom: 0;
        left: 0;
      }

      .post-con:nth-child(3) {
        width: 74rem;
        height: 51rem;
        top: 0;
        right: 0;
      }

      .post-con:nth-child(1n + 4) {
        display: none;
      }
    }

    .ft-model-m_333 {
      height: 42rem;
      position: relative;
      overflow: hidden;
      width: 120rem;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 2rem;

      .post-con {
        min-width: 29.5rem;
        flex: 1;
        height: 20rem;
        position: relative;
        overflow: hidden;
      }

      .post-con:nth-child(1n + 7) {
        display: none;
      }
    }

    .ft-model-m_14 {
      height: 41rem;
      position: relative;
      overflow: hidden;
      width: 120rem;
      margin: 0 auto;

      .post-con {
        position: absolute;
        overflow: hidden;
        .post-text {
          position: absolute;
        }
      }

      .post-con:nth-child(1) {
        width: 62rem;
        height: 41rem;
        top: 0;
        left: 0;
      }

      .post-con:nth-child(2),
      .post-con:nth-child(3),
      .post-con:nth-child(4),
      .post-con:nth-child(5) {
        width: 28rem;
        height: 20rem;
      }

      .post-con:nth-child(2) {
        top: 0;
        right: 29rem;
      }

      .post-con:nth-child(3) {
        bottom: 0;
        right: 29rem;
      }

      .post-con:nth-child(4) {
        top: 0;
        right: 0;
      }

      .post-con:nth-child(5) {
        bottom: 0;
        right: 0;
      }

      .post-con:nth-child(1n + 6) {
        display: none;
      }

      .post-con:hover {
        .post-img {
          transform: scale(1.05);
        }
        .post-text {
          bottom: 0rem;
          left: 0px;
          width: 100%;
        }
      }
    }

    .ft-model-m_1111 {
      height: 23rem;
      position: relative;
      overflow: hidden;
      width: 120rem;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      gap: 2rem;

      .post-con {
        flex: 1;
        height: 22rem;
        position: relative;
        overflow: hidden;
        cursor: pointer;
      }

      .post-con:nth-child(1n + 5) {
        display: none;
      }
    }
    .ft-model-m_2222 {
      height: 44rem;
      position: relative;
      overflow: hidden;
      width: 120rem;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 2rem;

      .post-con {
        flex: 1;
        height: 20rem;
        position: relative;
        overflow: hidden;
      }

      .post-con:nth-child(1n + 5) {
        display: none;
      }
    }
    .ft-model-m_autoSwipe {
      width: 120rem;
      height: auto;
      margin: 30px auto 40px;
      display: flex;
      justify-content: flex-start;
      flex-wrap: nowrap;
      overflow: hidden;
      gap: 20px;
      padding-bottom: 2rem;
      .post-con {
        flex-shrink: 0;
        width: 28.3rem;
        height: 42.3rem;
        background: no-repeat 50% / cover;
      }
    }
  }
}
</style>

