<template>
  <div class="web-item ifame">
    <div class="ifarm-con">
      <div class="item-title-container">
        <div class="item-title">
          {{ webItem.title }}
        </div>
      </div>
      <iframe
        :src="content.url"
        frameborder="0"
        width="100%"
        :height="content.height"
        scrolling="no"
      ></iframe>
    </div>
  </div>
</template>

<script>
import RD from "@/api/RD";

export default {
  name: "IndexIfame",
  components: {},
  props: {
    content: {
      type: Object,
      default: function () {
        return {};
      },
    },
    logined: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
  },
  data: function () {
    return { webItem: {} };
  },
  created() {
    this.reload();
  },
  methods: {
    reload() {
      setTimeout(() => {
        this.content.imgurl = RD.ad().id(this.content.id).image_URL();
        this.webItem = this.content;
      }, 1000);
    },
  },
};
</script>

<style lang="less" scoped>
@media all and (max-width: 800px) {
  .ifame {
    width: 750px;
    margin: 0px auto;
    // background: no-repeat url(../../assets/bg_index2.jpg);
    // background-size: cover;
  }
  .web-item {
    margin: 20px auto;
    .ifarm-con {
      width: 750px;
      margin: 0 auto;
      .item-title-container {
        width: 100%;
        margin: 0 auto;
        .item-title {
          position: relative;
          height: 10rem;
          width: 120rem;
          margin: 1.7rem auto;
          line-height: 10rem;
          text-align: left;
          font-size: 24px;
          .item-title {
            margin: 0 auto;
            cursor: pointer;
            display: inline-block;
            height: 7.5rem;
            padding: 0px 1.5rem;
          }
        }
      }
    }
  }
}
@media all and (min-width: 801px) {
  .ifame {
    width: 100%;
    margin: 0px auto;
    height: 68.4rem;
    // background: no-repeat url(../../assets/bg_index2.jpg);
    // background-size: cover;
  }
  .web-item {
    height: 68.4rem;
    background-color: #f1f1f1;

    margin: 20px auto;
    .ifarm-con {
      width: 120rem;
      margin: 0 auto;
      .ifame-detail {
        width: 100%;
        margin: 0 auto;
        .ifame-title {
          position: relative;
          height: 10rem;
          width: 120rem;
          margin: 1.7rem auto;
          line-height: 10rem;
          .item-title {
            margin: 0 auto;
            font-size: 2.2rem;
            cursor: pointer;
            display: inline-block;
            height: 7.5rem;
            padding: 0px 1.5rem;
            text-align: left;
          }
        }
      }
    }
  }
}
</style>

