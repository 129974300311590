<template class="whole">
  <div class="web-item">
    <div class="item-title-container">
      <div class="web-item-title" @click="onMoreFT(content)">
        {{ content.title }}
      </div>
      <div class="item-subtitle">
        <div class="item">{{ content.subTitle }}</div>
        <div class="item-red"></div>
      </div>
      <div class="web-item-more">
        <a href="/userShow.html" target="_blank">更多>>></a>
      </div>
    </div>

    <div
      class="userShow-con desktop-present"
      @mouseenter="usstopTimer()"
      @mouseleave="usrestartTimer()"
    >
      <!-- <div class="userShow-con"> -->
      <div class="userShow-list">
        <div class="userShow-swiper">
          <template v-for="(p, usindex) in content.list">
            <div
              class="userShow-list"
              :key="'userShow' + p.id + '_' + usindex"
              v-show="
                swipeFilter(usindex, content.list.length, swiperIndex, 4, 1)
              "
              :class="
                'pos-' +
                swipePosition(usindex, swiperIndex, content.list.length, 6)
              "
            >
              <div
                class="userShow-img"
                :style="{ backgroundImage: `url(` + p.imgURL + `)` }"
                @click="onUserDetail(p.id, 4)"
              ></div>
              <div class="userShow-text">{{ p.name }}</div>
              <div class="userShow-detail" @click="onUserDetail(p.id, 4)">
                <div class="detail-info">
                  <div class="name">{{ p.name }}</div>
                  <div class="red"></div>
                  <div class="detail" v-html="p.detail"></div>
                </div>
              </div>
              <!-- <div class="userShow-red"></div> -->
            </div>
          </template>
        </div>
      </div>
      <div class="swiper-left" @click="onTakeLeft">
        <div class="left"></div>
      </div>
      <div class="swiper-right" @click="onTakeRight">
        <div class="right"></div>
      </div>
    </div>
    <div
      class="mobile-present"
      @mouseenter="usstopTimer()"
      @mouseleave="usrestartTimer()"
    >
      <div class="us-list">
        <template v-for="(p, usindex) in content.list">
          <div
            class="us-detail"
            :key="'userShow' + p.id + '_' + usindex"
            v-show="
              swipeFilter(usindex, content.list.length, swiperIndex, 3, 1)
            "
            :class="
              'pos-' +
              swipePosition(usindex, swiperIndex, content.list.length, 5)
            "
          >
            <!-- {{ content.list.length }} -->
            <div
              class="userShow-img"
              :style="{ backgroundImage: `url(` + p.imgURL + `)` }"
              @click="onUserDetail(p.id, 4)"
            ></div>
            <div class="userShow-text">{{ p.name }}</div>
            <div class="userShow-detail" @click="onUserDetail(p.id, 4)">
              <div class="detail-info">
                <div class="name">{{ p.name }}</div>
                <div class="red"></div>
                <div class="detail" v-html="p.detail"></div>
              </div>
            </div>
            <div class="userShow-red"></div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
var ustimers = [];
import RD from "@/api/RD";

export default {
  name: "IndexUs",
  components: {},
  props: {
    content: {
      type: Object,
      default: function () {
        return {};
      },
    },
    logined: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
  },
  data() {
    return {
      swiperIndex: 0, // 轮播的Index
      swipeMoving: false,
    };
  },
  created() {
    this.reload();
  },
  methods: {
    reload() {
      var thiz = this;
      console.log("us", this.content.list);
      this.content.list.forEach((i) => {
        if (i.id) {
          i.imgURL = RD.userShow().id(i.id).headPic_URL();
        }
      });
      ustimers = setInterval(() => {
        (() => {
          thiz.move();
        })();
      }, 3000);
      this.$nextTick(() => {
        this.swipeMoving = false;
      });
    },
    /**
     * 处理nplist的位置函数，注入对应code入item的loc
     */
    npListProcess(content) {
      //npList npIndex
      let list = JSON.parse(JSON.stringify(content.npList));
      let index = content.npIndex;
      list.forEach((item) => {
        item.loc = undefined;
      });
      list[index].loc = 0;
      list[
        index + 1 >= list.length ? index + 1 - list.length : index + 1
      ].loc = 1;
      list[
        index + 2 >= list.length ? index + 2 - list.length : index + 2
      ].loc = 2;
      list[index - 1 < 0 ? index - 1 + list.length : index - 1].loc = -1;
      list[index - 2 < 0 ? index - 2 + list.length : index - 2].loc = -2;
      content.npList = list;
    },
    move() {
      if (this.swipeMoving) {
        return;
      }
      this.swipeMoving = true;
      this.swiperIndex += 1;
      setTimeout(() => {
        this.swipeMoving = false;
      }, 500);
    },
    usstopTimer() {
      clearInterval(ustimers);
    },
    usrestartTimer() {
      if (ustimers) {
        clearInterval(ustimers);
      }
      ustimers = setInterval(() => {
        (() => {
          this.move();
        })();
      }, 3000);
    },
    // 摄影师的点击
    onUserDetail(id, code) {
      let routerUrl = this.$router.resolve({
        path: "/userShowDetail.html",
        query: { id: id, code: code },
      });
      window.open(routerUrl.href, "_blank");
    },
    //
    onClickCatalog() {
      let routerUrl = this.$router.resolve({
        path: "/userShow.html",
      });
      window.open(routerUrl.href, "_blank");
    },
    // 往左翻
    onTakeLeft() {
      if (this.swipeMoving) {
        return;
      }
      this.swipeMoving = true;
      this.swiperIndex -= 1;
      setTimeout(() => {
        this.swipeMoving = false;
      }, 500);
    },
    // 往右翻
    onTakeRight() {
      if (this.swipeMoving) {
        return;
      }
      this.swipeMoving = true;
      this.swiperIndex += 1;
      setTimeout(() => {
        this.swipeMoving = false;
      }, 500);
    },
    // 鼠标move
    swipeLeft() {
      console.log("swipe left");
      clearInterval(ustimers);
      if (this.swipeMoving) {
        return;
      }
      this.swipeMoving = true;
      // this.npIndex = npindex
      this.swiperIndex += 1;
      setTimeout(() => {
        this.swipeMoving = false;
        this.usrestartTimer();
      }, 500);
    },
    swipeRight() {
      console.log("swipe right");
      clearInterval(ustimers);
      if (this.swipeMoving) {
        return;
      }
      this.swipeMoving = true;
      // this.npIndex = npindex
      this.swiperIndex -= 1;
      setTimeout(() => {
        this.swipeMoving = false;
        this.usrestartTimer();
      }, 500);
    },
    /**
     * swipe filter utils, return if the target index is show or not
     * @Param targetIndex item index to test
     * @Param length array length
     * @Param swipeIndex swipePosition to test
     * @Param forwardGap how many item in the array larger than swipeIndex will be marked as valid
     * @Param backwardGap how many item in the array smaller the swipeIndex will be marked as valid
     */
    swipeFilter(t, l, s, f, b) {
      return !((((t - s) % l) + l) % l > f && (((s - t) % l) + l) % l > b);
    },
    /**
     * return the position index
     * position when swipeIndex == targetIndex return 0
     * forward return  1 2 3
     * backword return total -1 total -2 total -3
     * @Param targetIndex
     * @Param swipeIndex
     * @Param total length of array
     * @Param length of items show
     */ swipePosition(t, s, l, g) {
      return Math.abs((t - (((s % l) + l) % l) + l) % l) <=
        Math.abs(((((s % l) + l) % l) - t + l) % l)
        ? ((((t - s) % l) + l) % l) % g
        : t > ((s % l) + l) % l
        ? (((-(((s % l) + l) % l) - l + t) % g) + g) % g
        : (((-(((s % l) + l) % l) + t) % g) + g) % g;
    },
  },
};
</script>

<style lang="less" scoped>
@media all and (max-width: 800px) {
  .web-item {
    background-color: #f7f7f7;
    margin-top: 30px !important;
  }
  .item-title-container {
    height: 7rem !important;
    width: 750px;
    margin: 3rem auto;
    position: relative;
    display: flex;
    overflow: hidden;
    .web-item-title {
      text-align: left;
      color: #000;
      margin-top: 2rem;
      width: auto;
      height: 4rem;
      line-height: 3.4rem;
      font-size: 34px;
      font-weight: lighter;
      cursor: pointer;
    }
    .item-subtitle {
      font-size: 20px;
      color: #aaa;
      height: 6rem;
      margin-left: 10px;
      font-weight: lighter;
      font-family: "Microsoft Yahei", "Simsun";
      position: relative;
      .item {
        line-height: 7rem;
      }
      .item-red {
        position: absolute;
        bottom: -5px;
        left: 50%;
        width: 80%;
        transform: translate(-50%);
        height: 3px;
        background-color: #d42d26;
      }
    }

    .web-item-more {
      position: absolute;
      color: #878787;
      cursor: pointer;
      right: 1.5rem;
      top: 2rem;
      height: 7rem;
      line-height: 5rem;
      font-size: 16px;
      a {
        color: #878787;
        // font-weight: lighter;
      }
    }

    .web-item-header-gap {
      position: absolute;
      background: #bfbfbf;
    }
  }

  .userShow-con {
    width: 750px;
    margin: 0 auto;
    position: relative;
    height: 34rem;
    margin-bottom: 5rem;

    .userShow-list {
      width: 750px;
      margin: 0 auto;
      overflow: hidden;
      position: absolute;
      height: 34rem;
      .userShow-swiper {
        display: flex;
        flex-wrap: nowrap;
        width: 100%;
        position: absolute;
        .userShow-list {
          flex-shrink: 0;
          width: 29rem;
          height: 31.8rem;
          background-color: #fff;
          margin-right: 5px;
          transition: all 500ms ease-in-out;
          .userShow-img {
            width: 15.6rem;
            height: 15.6rem;
            border-radius: 7.8rem;
            border: 0.083 solid #ccc;
            background: no-repeat 50% / cover;
            margin: 4.9rem auto 0rem;
            cursor: pointer;
          }

          .userShow-text {
            height: 5.6rem;
            line-height: 5.6rem;
            font-size: 2rem;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #444444;
            cursor: pointer;
            text-align: center;
            margin-top: 2.1rem;
          }
          .userShow-detail {
            width: 0rem;
            height: 0rem;
            transition: all 500ms ease-in-out;
            position: absolute;
            left: 0rem;
            top: 0rem;
            cursor: pointer;
            background-color: rgba(0, 0, 0, 0.66);
            opacity: 0;
            .detail-info {
              text-align: left;
              padding: 0 0.3rem;
              font-size: 1.5rem;

              letter-spacing: 2px;
              .name {
                font-size: 2.2rem;
                width: 15rem;
                height: 6rem;
                line-height: 7rem;
                text-align: center;
                margin: 6rem auto 0rem;
              }
              .red {
                width: 3.2rem;
                height: 4px;
                background: #e42121;
                margin: 0 auto;
              }
              .detail {
                text-indent: 2em;
                margin-top: 2rem;
                padding: 0 1rem;
                height: 16.5rem;
                line-height: 2.4rem;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 7;
                -webkit-box-orient: vertical;
              }
            }
          }
          .userShow-red {
            width: 3.2rem;
            height: 4px;
            background: #e42121;
            margin: 0 auto;
          }
        }
        .userShow-list:hover {
          .userShow-detail {
            width: 100%;
            height: 32.8rem;
            color: #fff;
            opacity: 1;
            font-size: 14px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
          }
        }

        .transformUp {
          transform: translateX(-29.5rem);
          transition: all 1000ms ease-in-out;
        }
      }
    }

    .userShow-list.pos-0 {
      left: 0rem;
    }
    .userShow-list.pos-1 {
      left: 30rem;
    }
    .userShow-list.pos-2 {
      left: 60rem;
    }
    .userShow-list.pos-3 {
      left: 90rem;
    }
    .userShow-list.pos-4 {
      left: 120rem;
    }
    .userShow-list.pos-5 {
      top: -1200rem;
      left: 120rem;
      width: 1px;
      height: 1px;
    }
    .userShow-list.pos-6 {
      top: -1200rem;
      left: -30rem;
      width: 1px;
      height: 1px;
    }
    .userShow-list.pos-7 {
      left: -30rem;
    }

    .swiper-left {
      position: absolute;
      left: -5rem;
      top: 40%;
      width: 5rem;
      height: 5rem;
      cursor: pointer;
      .left {
        height: 2.5rem;
        width: 2.5rem;
        border-top: 0.3rem solid #909099;
        border-left: 0.3rem solid #909099;
        transform: rotate(-45deg);
      }
    }
    .swiper-right {
      position: absolute;
      right: -5rem;
      top: 40%;
      width: 5rem;
      height: 5rem;
      cursor: pointer;
      .right {
        height: 2.5rem;
        width: 2.5rem;
        border-bottom: 0.3rem solid #909099;
        border-right: 0.3rem solid #909099;
        transform: rotate(-45deg);
      }
    }
  }
  .mobile-present {
    width: 750px;
    position: relative;
    height: 360px;
    .us-list {
      width: 750px;
      height: 400px;
      position: relative;
      overflow: hidden;
      transition: all 300ms ease-in-out;
      .us-detail {
        height: 288px;
        width: 222px;
        position: absolute;
        bottom: 750px;
        left: 222px;
        transition: all 300ms ease-in-out;
        background-color: #fff;
        .userShow-img {
          width: 136px;
          height: 136px;
          border-radius: 68px;
          border: 0.083 solid #ccc;
          background: no-repeat 50% / cover;
          margin: 4.9rem auto 0rem;
          cursor: pointer;
        }

        .userShow-text {
          height: 5.6rem;
          line-height: 5.6rem;
          font-size: 20px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #444444;
          cursor: pointer;
          text-align: center;
          margin-top: 2.1rem;
        }
        .userShow-detail {
          width: 0rem;
          height: 0rem;
          transition: all 500ms ease-in-out;
          position: absolute;
          left: 0rem;
          top: 0rem;
          background-color: rgba(0, 0, 0, 0.66);
          opacity: 0;
          .detail-info {
            text-align: left;
            padding: 0 0.3rem;
            font-size: 1.5rem;

            letter-spacing: 2px;
            .name {
              font-size: 2.2rem;
              width: 15rem;
              height: 6rem;
              line-height: 7rem;
              text-align: center;
              margin: 6rem auto 0rem;
            }
            .red {
              width: 30px;
              height: 4px;
              background: #e42121;
              margin: 0 auto;
            }
            .detail {
              text-indent: 2em;
              margin-top: 2rem;
              padding: 0 1rem;
              height: 16.5rem;
              line-height: 2.4rem;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 7;
              -webkit-box-orient: vertical;
            }
          }
        }
        .userShow-red {
          width: 30px;
          height: 4px;
          background: #e42121;
          margin: 6px auto 0px;
        }
      }

      .us-detail.pos-0 {
        height: 237px;
        width: 216px;
        top: 60px;
        left: 10px;
      }

      .us-detail.pos-1 {
        height: 288px;
        width: 258px;
        top: 30px;
        left: 240px;
        .userShow-img {
          margin-top: 60px;
        }
      }

      .us-detail.pos-2 {
        height: 237px;
        width: 216px;
        top: 60px;
        left: 520px;
      }

      .us-detail.pos-3 {
        top: 30px;
        left: 750px;
      }

      .us-detail.pos-4 {
        top: 30px;
        left: -333px;
      }
    }
  }
}
@media all and (min-width: 801px) {
  .web-item {
    background: #f7f7f7;
    margin-top: 4rem !important;
    .item-title-container {
      height: 7rem !important;
      width: 120rem;
      margin: 3rem auto;
      position: relative;
      display: flex;
      .web-item-title {
        text-align: left;
        color: #000;
        margin-top: 2rem;
        width: auto;
        height: 4rem;
        line-height: 3.4rem;
        font-size: 34px;
        font-weight: lighter;
        cursor: pointer;
      }
      .item-subtitle {
        font-size: 20px;
        color: #aaa;
        height: 6rem;
        margin-left: 10px;
        font-weight: lighter;
        font-family: "Microsoft Yahei", "Simsun";
        position: relative;
        .item {
          line-height: 7rem;
        }
        .item-red {
          position: absolute;
          bottom: 5px;
          left: 50%;
          width: 70%;
          transform: translate(-50%);
          height: 3px;
          background-color: #d42d26;
        }
      }

      .web-item-more {
        position: absolute;
        color: #878787;
        cursor: pointer;
        right: 1.5rem;
        top: 2rem;
        height: 5rem;
        line-height: 5rem;
        font-size: 1.6rem;
        a {
          color: #878787;
          // font-weight: lighter;
        }
      }

      .web-item-header-gap {
        position: absolute;
        background: #bfbfbf;
      }
    }
  }
  .userShow-con {
    width: 120rem;
    margin: 0 auto;
    position: relative;
    height: 34rem;
    margin-bottom: 5rem;

    .userShow-list {
      width: 120rem;
      margin: 0 auto;
      overflow: hidden;
      position: absolute;
      height: 34rem;
      .userShow-swiper {
        display: flex;
        flex-wrap: nowrap;
        width: 100%;
        position: absolute;
        .userShow-list {
          flex-shrink: 0;
          width: 29rem;
          height: 31.8rem;
          background-color: #fff;
          margin-right: 5px;
          transition: all 500ms ease-in-out;
          .userShow-img {
            width: 15.6rem;
            height: 15.6rem;
            border-radius: 7.8rem;
            border: 0.083 solid #ccc;
            background: no-repeat 50% / cover;
            margin: 4.9rem auto 0rem;
            cursor: pointer;
          }

          .userShow-text {
            height: 5.6rem;
            line-height: 5.6rem;
            font-size: 2rem;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #444444;
            cursor: pointer;
            text-align: center;
            margin-top: 2.1rem;
          }
          .userShow-detail {
            width: 0rem;
            height: 0rem;
            transition: all 500ms ease-in-out;
            position: absolute;
            left: 0rem;
            top: 0rem;
            background-color: rgba(0, 0, 0, 0.66);
            opacity: 0;
            cursor: pointer;
            .detail-info {
              text-align: left;
              padding: 0 0.3rem;
              font-size: 1.5rem;

              letter-spacing: 2px;
              .name {
                font-size: 2.2rem;
                width: 15rem;
                height: 6rem;
                line-height: 7rem;
                text-align: center;
                margin: 6rem auto 0rem;
              }
              .red {
                width: 3.2rem;
                height: 4px;
                background: #e42121;
                margin: 0 auto;
              }
              .detail {
                text-indent: 2em;
                margin-top: 2rem;
                padding: 0 1rem;
                height: 16.5rem;
                line-height: 2.4rem;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 7;
                -webkit-box-orient: vertical;
              }
            }
          }
          .userShow-red {
            width: 3.2rem;
            height: 4px;
            background: #e42121;
            margin: 0 auto;
          }
        }
        .userShow-list:hover {
          .userShow-detail {
            width: 100%;
            height: 32.8rem;
            color: #fff;
            opacity: 1;
            font-size: 14px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
          }
        }

        .transformUp {
          transform: translateX(-29.5rem);
          transition: all 1000ms ease-in-out;
        }
      }
    }

    .userShow-list.pos-0 {
      left: 0rem;
    }
    .userShow-list.pos-1 {
      left: 30rem;
    }
    .userShow-list.pos-2 {
      left: 60rem;
    }
    .userShow-list.pos-3 {
      left: 90rem;
    }
    .userShow-list.pos-4 {
      left: 120rem;
    }
    .userShow-list.pos-5 {
      top: 0rem;
      left: -30rem;
      width: 1px;
      height: 1px;
    }
    // .userShow-list.pos-6 {
    //   top: -1200rem;
    //   left: -30rem;
    //   width: 1px;
    //   height: 1px;
    // }
    // .userShow-list.pos-7 {
    //   left: -30rem;
    // }

    .swiper-left {
      position: absolute;
      left: -5rem;
      top: 40%;
      width: 5rem;
      height: 5rem;
      cursor: pointer;
      .left {
        height: 2.5rem;
        width: 2.5rem;
        border-top: 0.3rem solid #909099;
        border-left: 0.3rem solid #909099;
        transform: rotate(-45deg);
      }
    }
    .swiper-right {
      position: absolute;
      right: -5rem;
      top: 40%;
      width: 5rem;
      height: 5rem;
      cursor: pointer;
      .right {
        height: 2.5rem;
        width: 2.5rem;
        border-bottom: 0.3rem solid #909099;
        border-right: 0.3rem solid #909099;
        transform: rotate(-45deg);
      }
    }
  }
}
</style>

