<template >
  <div class="reperPic">
    <div class="web-item">
      <div class="web-item-header">
        <!-- <div class="camera-icon web-item-icon"></div> -->
        <div class="web-item-title">
          <a href="/fixTopic.html?id=8" target="_blank">{{ webItem.title }}</a>
        </div>
        <div class="web-item-more">
          <a href="/fixTopic.html?id=8" target="_blank">更多>></a>
        </div>
        <!-- <div class="web-item-header-gap"></div> -->
      </div>
      <!-- <div class="activity-con"> -->

      <div class="reperPic-list">
        <div class="list-con">
          <a
            class="reperPic-img"
            v-for="p in content.list"
            :key="'' + ' ' + p.postId + '_paper'"
            :href="'post.html?id=' + p.postId"
            target="blank"
          >
            <div
              class="reper-img"
              :style="{ backgroundImage: `url(` + p.imgURL + `)` }"
            ></div>
            <!-- <div class="text-title">{{ p.title }}</div> -->
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RD from "@/api/RD";

export default {
  name: "IndexPFT",
  components: {},
  props: {
    content: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data: function () {
    return {
      logined: false,
      webItem: {},
    };
  },
  created() {
    this.reload();
  },
  methods: {
    reload() {
      setTimeout(() => {
        this.content.list.forEach((i) => {
          if (i.frontPicId) {
            i.imgURL = RD.pic().id(i.frontPicId).mImage_URL();
          }
        });

        this.webItem = this.content;
      }, 1000);
    },
  },
};
</script>

<style lang="less" scoped>
// .camera-icon {
//   background: no-repeat 50% / contain url(../../assets/camera.png);
// }
@media all and (max-width: 800px) {
  .reperPic {
    width: 750px;
    margin: 0px auto;
    height: 59rem;
    // background: no-repeat url(../../assets/bg_index1.jpg);
    // background-size: cover;
  }
  .web-item {
    width: 750px;
    margin: 0 auto;
    .web-item-header {
      position: relative;
      margin-top: 20px;
      height: 50px;
      line-height: 50px;
      .web-item-title {
        font-size: 24px;
      }
      .web-item-more {
        position: absolute;
        right: 5px;
        top: 5px;
        font-size: 15px;
        a {
          text-decoration: none;
          color: #000;
        }
      }
    }
  }
  .reperPic-list {
    height: 44rem;
    position: relative;
    overflow: hidden;
    margin-top: 2rem;
    .list-con {
      display: flex;
      height: 100%;
      width: 100%;
      overflow: hidden;
      position: absolute;
      top: 0rem;
      left: 0rem;
      flex-shrink: 0;
      flex-wrap: wrap;
      justify-content: space-between;
      a {
        text-decoration: none;
        color: #000;
      }
      .reperPic-img {
        width: 28rem;
        height: 100%;
        // margin-top: 2rem;
        position: relative;
        cursor: pointer;
        .reper-img {
          height: 46.3rem;
          width: 100%;
          background: no-repeat 50% / cover;
        }
        .text-title {
          width: 100%;
          height: 10%;
          line-height: 5rem;
          font-size: 1.3rem;
          text-align: center;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}
@media all and (min-width: 801px) {
  .reperPic {
    width: 100%;
    margin: 0px auto;
    height: 59rem;
  }
  .web-item {
    width: 120rem;
    margin: 0 auto;
    .web-item-more {
      a {
        text-decoration: none;
        color: #000;
      }
    }
  }
  .reperPic-list {
    height: 44rem;
    position: relative;
    overflow: hidden;
    margin-top: 2rem;
    .list-con {
      display: flex;
      height: 100%;
      width: 100%;
      overflow: hidden;
      position: absolute;
      top: 0rem;
      left: 0rem;
      flex-shrink: 0;
      flex-wrap: wrap;
      justify-content: space-between;
      a {
        text-decoration: none;
        color: #000;
      }
      .reperPic-img {
        width: 28rem;
        height: 100%;
        // margin-top: 2rem;
        position: relative;
        cursor: pointer;
        .reper-img {
          height: 46.3rem;
          width: 100%;
          background: no-repeat 50% / cover;
        }
        .text-title {
          width: 100%;
          height: 10%;
          line-height: 5rem;
          font-size: 1.3rem;
          text-align: center;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}
</style>

