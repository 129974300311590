<template>
  <div class="ad">
    <div class="ad-con">
      <div class="ad-list">
        <!-- <a :href="webItem.url" target="_blank"> -->
        <img class="ad-detail" :src="webItem.imgurl" />
        <!-- </a> -->
      </div>
    </div>
  </div>
</template>

<script>
import RD from "@/api/RD";

export default {
  name: "IndexAd",
  components: {},
  props: {
    content: {
      type: Object,
      default: function () {
        return {};
      },
    },
    logined: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
  },
  data: function () {
    return { webItem: {} };
  },
  created() {
    this.reload();
  },
  methods: {
    reload() {
      setTimeout(() => {
        this.content.imgurl =
          RD.ad().id(this.content.id).image_URL() + "?_" + new Date().getTime();
        this.webItem = this.content;
      }, 1000);
    },
  },
};
</script>

<style lang="less" scoped>
@media all and (max-width: 800px) {
  .ad {
    width: 120rem;
    margin: 20px auto;

    .ad-con {
      height: 7.5rem;
      width: 100%;
      .ad-list {
        height: 100%;
        .ad-detail {
          width: 100%;
          height: 100%;
          background: cover;
        }
        // }
      }
    }
  }
}
@media all and (min-width: 801px) {
  .ad {
    width: 120rem;
    margin: 20px auto;

    .ad-con {
      height: 7.5rem;
      width: 100%;
      .ad-list {
        height: 100%;
        // a {
        //   width: 100%;
        //   height: 100%;
        .ad-detail {
          width: 100%;
          height: 100%;
          background: cover;
        }
        // }
      }
    }
  }
}
</style>

