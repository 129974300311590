import { render, staticRenderFns } from "./announce.vue?vue&type=template&id=52567683&scoped=true&"
import script from "./announce.vue?vue&type=script&lang=js&"
export * from "./announce.vue?vue&type=script&lang=js&"
import style0 from "./announce.vue?vue&type=style&index=0&id=52567683&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52567683",
  null
  
)

export default component.exports