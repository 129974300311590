<template>
  <div
    class="front-show"
    @mouseenter="stopTimer()"
    @mouseleave="restartTimer()"
  >
    <div class="pic-container">
      <div
        v-for="(pic, contentIndex) in topShowList"
        :key="pic + 'pic_' + contentIndex"
        class="front-pic"
        :title="topShowList[picIndex].title"
        :class="{ active: picIndex == contentIndex }"
        @click="onTopShowPost(picIndex)"
      >
        <div
          class="pic-inner"
          :style="{ backgroundImage: `url(` + pic.imgURL + `)` }"
        ></div>
      </div>
    </div>
    <div class="nav-container">
      <div
        v-for="(pic, navIndex) in topShowList"
        :key="pic.id + '_nav' + navIndex"
        class="nav-pic"
        :class="{ active: picIndex == navIndex }"
        @click="scrollTo(navIndex)"
      ></div>
    </div>
    <!-- <div class="nav-search">
      <div class="search">
        <el-input
          v-model="keyWord"
          placeholder=""
          @keyup.enter.native="onSearach"
        ></el-input>
        <div class="search-item" @click="onSearach">搜索</div>
      </div>
    </div> -->
  </div>
</template>

<script>
var timers = [];
import RD from "@/api/RD";

export default {
  name: "IndexTopShow",
  components: {},
  props: {
    content: {
      type: Object,
      default: function () {
        return {};
      },
    },
    logined: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
  },
  data: function () {
    return {
      topShowList: [], // 控制显示前6个稿子
      keyWord: "", // 搜索的关键字
      picIndex: 0,
    };
  },
  created() {
    this.reload();
  },
  destroyed() {
    clearInterval(timers);
  },
  methods: {
    reload() {
      var thiz = this;
      setTimeout(() => {
        thiz.content.list.forEach((i) => {
          if (i.frontPicId) {
            i.imgURL = RD.pic().id(i.frontPicId).tImage_URL();
          }
        });
        thiz.topShowList = thiz.content.list.splice(0, 8);
      }, 1000);
      timers = setInterval(() => {
        this.next();
      }, 3000);
    },
    next() {
      var thiz = this;
      thiz.picIndex++;
      if (thiz.picIndex >= thiz.topShowList.length) {
        thiz.picIndex = 0;
      }
    },
    stopTimer() {
      clearInterval(timers);
    },
    restartTimer() {
      if (timers) {
        clearInterval(timers);
      }
      timers = setInterval(() => {
        this.next();
      }, 2000);
    },
    scrollTo(navIndex) {
      this.picIndex = navIndex;
    },
    // 轮播的的点击, topshow模块调用了
    onFrontPost(contentIndex) {
      var postId = this.topShowList[contentIndex].postId;

      this.$router.push({
        path: "/post_" + postId + ".html",
      });
    },
    // 轮播的稿件查看
    onTopShowPost(navIndex) {
      console.log("kl", navIndex);
      var postId = this.topShowList[navIndex].postId;
      this.$router.push({
        path: "/post.html",
        query: { id: postId },
      });
    },
  },
};
</script>

<style lang="less" scoped>
@media all and (max-width: 800px) {
  .front-show {
    height: 486px;
    position: relative;
    margin: 0px auto;
    left: 0px;
    right: 0px;

    .pic-container {
      position: relative;
      width: 100%;
      height: 100%;
      overflow: hidden;

      .front-pic {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        opacity: 0;
        transition: all 500ms ease-in-out;
        cursor: pointer;

        .pic-inner {
          position: absolute;
          top: 0px;
          left: 0px;
          width: 100%;
          height: 100%;
          background: no-repeat 50% / cover;
        }

        .pic-inner-left {
          position: absolute;
          top: 0px;
          left: -50%;
          width: 100%;
          height: 100%;
          overflow: hidden;

          .inner {
            position: absolute;
            top: 0px;
            right: -100.1%;
            width: 200%;
            height: 100%;
            filter: blur(10px);
            transform: scaleX(-1);
            background: repeat 50% / contain;
          }
        }

        .pic-inner-right {
          position: absolute;
          top: 0px;
          right: -50%;
          width: 100%;
          height: 100%;
          overflow: hidden;

          .inner {
            position: absolute;
            top: 0px;
            left: -100.1%;
            width: 200%;
            height: 100%;
            filter: blur(10px);
            transform: scaleX(-1);
            background-color: black;
            background: repeat 50% / contain;
          }
        }
      }

      .front-pic.active {
        opacity: 1;
      }
    }

    .nav-container {
      position: absolute;
      bottom: 10px;
      right: 53%;
      transform: translate(20rem);
      width: 215px;
      height: 15px;
      display: flex;
      justify-content: space-between;

      .nav-pic {
        height: 12.5px;
        width: 12.5px;
        border-radius: 6.6px;
        border: 1px solid rgba(255, 255, 255, 0.6);
        margin-right: 8.5px;
        background: rgba(0, 0, 0, 0.5);
        transition: all 500ms ease-in-out;
        cursor: pointer;
      }

      .nav-pic.active {
        background-color: #d7140c;
        width: 42px;
      }
    }
  }
}
@media all and (min-width: 801px) {
  .front-show {
    height: 68rem;
    position: relative;
    margin: 0px auto;
    max-width: 120rem;
    left: 0px;
    right: 0px;
    margin-bottom: 5rem;
    .pic-container {
      position: relative;
      width: 100%;
      height: 100%;
      overflow: hidden;

      .front-pic {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        opacity: 0;
        transition: all 500ms ease-in-out;
        cursor: pointer;

        .pic-inner {
          position: absolute;
          top: 0px;
          left: 0px;
          width: 100%;
          height: 100%;
          background: no-repeat 50% / cover;
        }

        .pic-inner-left {
          position: absolute;
          top: 0px;
          left: -50%;
          width: 100%;
          height: 100%;
          overflow: hidden;

          .inner {
            position: absolute;
            top: 0px;
            right: -100.1%;
            width: 200%;
            height: 100%;
            filter: blur(10px);
            transform: scaleX(-1);
            background: repeat 50% / contain;
          }
        }

        .pic-inner-right {
          position: absolute;
          top: 0px;
          right: -50%;
          width: 100%;
          height: 100%;
          overflow: hidden;

          .inner {
            position: absolute;
            top: 0px;
            left: -100.1%;
            width: 200%;
            height: 100%;
            filter: blur(10px);
            transform: scaleX(-1);
            background-color: black;
            background: repeat 50% / contain;
          }
        }
      }

      .front-pic.active {
        opacity: 1;
      }
    }

    .nav-container {
      position: absolute;
      bottom: 0rem;
      right: 53%;
      transform: translate(20rem);
      width: 25rem;
      height: 2.5rem;
      display: flex;
      justify-content: space-between;

      .nav-pic {
        height: 1.25rem;
        width: 1.25rem;
        border-radius: 0.66rem;
        // border: 1px solid rgba(255, 255, 255, 0.6);
        margin-right: 0.85rem;
        background: #d1d2d4;
        transition: all 500ms ease-in-out;
        cursor: pointer;
      }

      .nav-pic.active {
        background-color: #d7140c;
        width: 4.2rem;
      }
    }

    .nav-search {
      display: flex;
      margin-top: 18px;
      width: 50rem;
      position: absolute;
      bottom: 6rem;
      left: 8%;
      z-index: 10;
      .search {
        width: 50rem;
        height: 4rem;
        overflow: hidden;
        margin-left: 10px;
        position: relative;
        ::v-deep .el-input {
          height: 30px;
          .el-input__inner {
            height: 4rem;
            border-radius: 10px;
            background-color: rgba(0, 0, 0, 0.3);
          }
        }
        .icon {
          position: absolute;
          right: 6px;
          top: 7px;
          width: 20px;
          height: 20px;
          // background: no-repeat url(../assets/search_icon.png);
          background-size: cover;
          cursor: pointer;
        }
        .search-item {
          position: absolute;
          right: 0px;
          top: 0px;
          // background-color: #f36c72;
          color: #ccc;
          font-size: 1.8rem;
          width: 10rem;
          height: 4rem;
          line-height: 4rem;
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
          cursor: pointer;
        }
      }
    }
  }
}
</style>

