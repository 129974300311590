<template>
  <div class="ftm">
    <div class="ftm-con">
      <div class="web-item-content">
        <div class="ftm-model-m_mix22" v-if="webItem.model == 'm_mix22'">
          <div class="mix-left">
            <div class="left-name">精彩图库</div>
            <div class="left-con">
              <div class="local">
                <div class="local-img"></div>
                <div class="local-text">
                  <a href="local.html" target="_blank"
                    ><span>县区图库> ></span></a
                  >
                </div>
              </div>
              <div class="sheyingjidi">
                <div class="sheying-img"></div>
                <div class="sheying-text">
                  <a href="" target="_blank"><span>摄影基地> ></span></a>
                </div>
              </div>
            </div>
          </div>
          <div class="mix-right">
            <div class="right-name">
              <div class="r-name">赛事活动</div>
              <div class="r-more">
                <a href="activity.html" target="_blank">更多></a>
              </div>
            </div>
            <div class="activity-list">
              <div
                class="ac-item"
                v-for="k in activityList"
                :key="'ac_' + k.id"
              >
                <div
                  class="ac-img"
                  :style="{ backgroundImage: `url(` + k.imgURL + `)` }"
                  @click="onActivity(k.id)"
                ></div>
              </div>
            </div>
          </div>
        </div>
        <div :class="'fts-model-' + webItem.model" v-else>
          <div
            class="post-con"
            v-for="(topic, topicIndex) in webItem.list"
            :key="'fts_' + topicIndex"
            @click="
              topic.toTopic
                ? onTopicClick(topic.id)
                : onTopicPostClick(topic.postId)
            "
          >
            <div
              class="post-img"
              :style="{ backgroundImage: `url(` + topic.imgURL + `)` }"
            ></div>
            <div class="post-text">
              {{ topic.title }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RD from "@/api/RD";

export default {
  name: "IndexFTM",
  components: {},
  props: {
    content: {
      type: Object,
      default: function () {
        return {};
      },
    },
    logined: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
  },
  data: function () {
    return {
      webItem: {},
      activityList: [], // 活动列表
    };
  },
  created() {
    this.reload();
  },
  methods: {
    reload() {
      setTimeout(() => {
        this.webItem = this.content;
        var thiz = this;
        thiz.activityList = thiz.$rtm.store.activityList;
      }, 1000);
    },
    // 打开活动详情页
    onActivity(acId) {
      this.$router.push({
        path: "/activityDetail.html",
        query: { code: acId },
      });
    },
  },
};
</script>

<style lang="less" scoped>
@media all and (max-width: 800px) {
  .ftm {
    width: 750px;
    margin: 0px auto;
    height: 38.9rem;
    background-size: cover;
    .ftm-con {
      height: 100%;
      width: 750px;
      margin: 0 auto;
      .web-item-content {
        height: 100%;
        .ftm-model-m_mix22 {
          display: flex;
          height: 100%;
          .mix-left {
            width: 50%;
            height: 28.4rem;
            margin-top: 5.5rem;
            .left-name {
              text-align: left;
              height: 3.4rem;
              font-size: 3rem;
              margin-bottom: 3rem;
            }
            .left-con {
              display: flex;
              .local,
              .sheyingjidi {
                height: 22rem;
                width: 50%;
                position: relative;
                margin-right: 1rem;
                .local-img {
                  height: 100%;
                  // background: no-repeat url(../../assets/jc1.jpg);
                  background-size: cover;
                }
                .sheying-img {
                  height: 100%;
                  // background: no-repeat url(../../assets/jc2.jpg);
                  background-size: cover;
                }
                .local-text,
                .sheying-text {
                  position: absolute;
                  bottom: 0px;
                  left: 0px;
                  width: 100%;
                  height: 4rem;
                  line-height: 4rem;
                  background-color: rgba(0, 0, 0, 0.5);
                  color: #fff;
                  font-size: 1.8rem;
                  a {
                    display: inline-block;
                    width: 100%;
                    height: 100%;
                    color: #fff;
                    span {
                      position: absolute;
                      right: 1rem;
                      bottom: 0px;
                      text-align: right;
                      margin-right: 1rem;
                    }
                  }
                }
              }
            }
          }
          .mix-right {
            width: 50%;
            height: 28.4rem;
            margin-top: 5.5rem;
            .right-name {
              display: flex;
              position: relative;
              .r-name {
                text-align: left;
                height: 3.4rem;
                font-size: 3rem;
                margin-bottom: 3rem;
              }
              .r-more {
                position: absolute;
                right: 0px;
                top: 8px;
                font-size: 15px;
                a {
                  display: inline-block;
                  width: 100%;
                  height: 100%;
                }
              }
            }
            .activity-list {
              display: flex;
              justify-content: space-between;
              .ac-item {
                width: 29.5rem;
                height: 22rem;
                .ac-img {
                  width: 100%;
                  height: 100%;
                  background: no-repeat 50% / cover;
                  cursor: pointer;
                }
              }
              .ac-item:nth-child(2 + n) {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}
@media all and (min-width: 801px) {
  .ftm {
    width: 100%;
    margin: 0px auto;
    height: 38.9rem;
    background-size: cover;
    .ftm-con {
      height: 100%;
      width: 120rem;
      margin: 0 auto;
      .web-item-content {
        height: 100%;
        .ftm-model-m_mix22 {
          display: flex;
          height: 100%;
          .mix-left {
            width: 50%;
            height: 28.4rem;
            margin-top: 5.5rem;
            .left-name {
              text-align: left;
              height: 3.4rem;
              font-size: 3rem;
              margin-bottom: 3rem;
            }
            .left-con {
              display: flex;
              .local,
              .sheyingjidi {
                height: 22rem;
                width: 50%;
                position: relative;
                margin-right: 1rem;
                .local-img {
                  height: 100%;
                  // background: no-repeat url(../../assets/jc1.jpg);
                  background-size: cover;
                }
                .sheying-img {
                  height: 100%;
                  // background: no-repeat url(../../assets/jc2.jpg);
                  background-size: cover;
                }
                .local-text,
                .sheying-text {
                  position: absolute;
                  bottom: 0px;
                  left: 0px;
                  width: 100%;
                  height: 4rem;
                  line-height: 4rem;
                  background-color: rgba(0, 0, 0, 0.5);
                  color: #fff;
                  font-size: 1.8rem;
                  a {
                    display: inline-block;
                    width: 100%;
                    height: 100%;
                    color: #fff;
                    span {
                      position: absolute;
                      right: 1rem;
                      bottom: 0px;
                      text-align: right;
                      margin-right: 1rem;
                    }
                  }
                }
              }
            }
          }
          .mix-right {
            width: 50%;
            height: 28.4rem;
            margin-top: 5.5rem;
            .right-name {
              display: flex;
              position: relative;
              .r-name {
                text-align: left;
                height: 3.4rem;
                font-size: 3rem;
                margin-bottom: 3rem;
              }
              .r-more {
                position: absolute;
                right: 0px;
                top: 16px;
                font-size: 1.5rem;
                a {
                  display: inline-block;
                  width: 100%;
                  height: 100%;
                }
              }
            }
            .activity-list {
              display: flex;
              justify-content: space-between;
              .ac-item {
                width: 29.5rem;
                height: 22rem;
                .ac-img {
                  width: 100%;
                  height: 100%;
                  background: no-repeat 50% / cover;
                  cursor: pointer;
                }
              }
              .ac-item:nth-child(2 + n) {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}
</style>

