<template>
  <div class="local">
    <div class="local-con">
      <div class="local-title">
        <div class="web-item-title" @click="">
          <a href="/local.html" target="_blank">{{ content.title }}</a>
        </div>
        <div class="item-subtitle">
          <div class="item">{{ content.subTitle }}</div>
          <div class="item-red"></div>
        </div>

        <div class="web-item-more">
          <a href="/local.html" target="_blank">更多>>></a>
        </div>
      </div>
      <div class="local-list">
        <div class="list-con">
          <localShow :idList="addrList"></localShow>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RD from "@/api/RD";
import localShow from "@/views/localShow";

export default {
  name: "IndexLc",
  components: { localShow },
  props: {
    content: {
      type: Object,
      default: function () {
        return {};
      },
    },
    logined: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
  },
  data: function () {
    return {
      webItem: {},
      addrList: [],
    };
  },
  created() {
    this.reload();
  },
  methods: {
    reload() {
      setTimeout(() => {
        this.content.imgurl = RD.ad().id(this.content.id).image_URL();
        this.webItem = this.content;
        this.addrList = this.content.list[0];
      }, 1000);
    },
  },
};
</script>

<style lang="less" scoped>
@media all and (max-width: 800px) {
  .local-con {
    height: 100%;
    width: 750px;
    position: relative;
    .local-title {
      height: 7rem;
      line-height: 7rem;
      text-align: left;
      position: relative;
      display: flex;
      margin: 3rem auto;
      .web-item-title {
        text-align: left;
        margin-top: 2rem;
        width: auto;
        height: 4rem;
        line-height: 3.4rem;
        font-size: 34px;
        font-weight: lighter;
        cursor: pointer;
      }
      .item-subtitle {
        font-size: 20px;
        color: #aaa;
        height: 6rem;
        margin-left: 10px;
        font-weight: lighter;
        font-family: "Microsoft Yahei", "Simsun";
        position: relative;
        .item-red {
          position: absolute;
          bottom: 0px;
          left: 50%;
          width: 70%;
          transform: translate(-50%);
          height: 3px;
          background-color: #d42d26;
        }
      }

      .web-item-more {
        position: absolute;
        color: #878787;
        cursor: pointer;
        right: 1.5rem;
        top: 0.5rem;
        font-size: 1.6rem;
        a {
          color: #878787;
        }
      }
    }
    .local-list {
      height: 48rem;
      position: relative;
      margin-top: 1rem;
      .list-con {
        display: flex;
        height: 100%;
        width: 100%;
        position: absolute;
        overflow: hidden;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-between;
        .local-img {
          width: 57.7rem;
          height: 47rem;
          margin: 2rem 1rem;
          background: no-repeat 50% / cover;
          position: relative;
          .addrImg {
            height: 100%;
            width: 100%;
            position: relative;
            background: no-repeat 50% / cover;
            .text-title {
              width: 5rem;
              position: absolute;
              font-size: 1.3rem;
              top: 18.1rem;
              text-align: center;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            .text-title:nth-child(2) {
              left: 5rem;
            }
            .text-title:nth-child(3) {
              left: 10rem;
            }
            .text-title:nth-child(4) {
              left: 15rem;
            }
          }
        }
      }
    }
  }
}
@media all and (min-width: 801px) {
  .local {
    width: 120rem;
    height: 60rem;
    margin: 0 auto;
    .local-con {
      height: 100%;
      width: 100%;
      position: relative;
      .local-title {
        height: 7rem;
        line-height: 7rem;
        text-align: left;
        position: relative;
        display: flex;
        margin: 3rem auto;
        .web-item-title {
          text-align: left;
          margin-top: 2rem;
          width: auto;
          height: 4rem;
          line-height: 3.4rem;
          font-size: 34px;
          font-weight: lighter;
          cursor: pointer;
        }
        .item-subtitle {
          font-size: 20px;
          color: #aaa;
          height: 6rem;
          margin-left: 10px;
          font-weight: lighter;
          font-family: "Microsoft Yahei", "Simsun";
          position: relative;
          .item-red {
            position: absolute;
            bottom: 8px;
            left: 50%;
            width: 70%;
            transform: translate(-50%);
            height: 3px;
            background-color: #d42d26;
          }
        }

        .web-item-more {
          position: absolute;
          color: #878787;
          cursor: pointer;
          right: 1.5rem;
          top: 0.5rem;
          font-size: 1.6rem;
          a {
            color: #878787;
          }
        }
      }

      .local-list {
        height: 48rem;
        position: relative;
        margin-top: 1rem;
        .list-con {
          display: flex;
          height: 100%;
          width: 100%;
          position: absolute;
          overflow: hidden;
          flex-wrap: wrap;
          flex-direction: row;
          justify-content: space-between;
          .local-img {
            width: 57.7rem;
            height: 47rem;
            margin: 2rem 1rem;
            background: no-repeat 50% / cover;
            position: relative;
            .addrImg {
              height: 100%;
              width: 100%;
              position: relative;
              background: no-repeat 50% / cover;
              .text-title {
                width: 5rem;
                position: absolute;
                font-size: 1.3rem;
                top: 18.1rem;
                text-align: center;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
              .text-title:nth-child(2) {
                left: 5rem;
              }
              .text-title:nth-child(3) {
                left: 10rem;
              }
              .text-title:nth-child(4) {
                left: 15rem;
              }
            }
          }
        }
      }
    }
  }
}
</style>

