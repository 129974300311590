<template>
  <div class="web-item">
    <div class="web-item-header" :class="'model_' + content.model">
      <div class="web-item-title" @click="onMoreFTS(webItem)">
        {{ content.title }}
      </div>
      <div class="item-subtitle">
        <div class="item">{{ content.subTitle }}</div>
        <div class="item-red"></div>
      </div>

      <!-- <div v-if="webItem.model == 'm_2222'" class="m_2222-title">
        <div
          class="view-item"
          v-for="k in viewList"
          :key="k.id"
          :class="{ primary: k.id == 1 }"
        >
          <div class="view">
            <div class="view-name">
              <a v-if="k.dis" @click="onUserMore">{{ k.name }}</a>
              <a v-else :href="k.url" target="_blank">{{ k.name }}</a>
            </div>
            <div class="subs">
              <div class="subs-item" v-for="h in k.subs" :key="h.id">
                {{ h.name }}
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <div class="web-item-more" @click="onMoreFTS(webItem)">更多>>></div>
    </div>
    <div class="web-item-content">
      <div class="fts-model-m_22221" v-if="webItem.model == '2222D'">
        <div
          class="post-con"
          v-for="(topic, topicIndex) in webItem.list"
          :key="'fts_' + topicIndex"
          @click="onTopicClick(topic.id)"
        >
          <div
            class="post-img"
            :style="{ backgroundImage: `url(` + topic.imgURL + `)` }"
          ></div>
          <div class="post-text">
            {{ topic.title }}
          </div>
        </div>
      </div>
      <div class="fts-model-m_1122" v-if="webItem.model == 'm_1122'">
        <div
          class="post-con"
          v-for="(topic, topicIndex) in webItem.list"
          :key="'fts_' + topicIndex"
          @click="onTopicClick(topic.id)"
        >
          <div
            v-if="topicIndex == 1"
            class="post-img"
            :style="{ backgroundImage: `url(` + topic.mImgURL + `)` }"
          ></div>
          <div
            v-else
            class="post-img"
            :style="{ backgroundImage: `url(` + topic.imgURL + `)` }"
          ></div>
          <div class="post-text">
            {{ topic.title }}
          </div>
        </div>
      </div>
      <div class="fts-model-m_top" v-if="webItem.model == 'm_top'">
        <!-- <div class="swiper-left">
          <div class="left" @click="onLeft">
            <div class="left-icon"></div>
          </div>
        </div>
        <div class="swiper-right">
          <div class="right" @click="onRight">
            <div class="right-icon"></div>
          </div>
        </div> -->
        <div class="swiper-list">
          <template v-for="(pic, contentIndex) in webItem.list">
            <div
              :key="contentIndex + 'FTS' + pic.id"
              class="pic"
              v-show="
                swipeFilter(contentIndex, webItem.list.length, swipeIndex, 4, 1)
              "
              :class="
                'pos-' +
                swipePosition(contentIndex, swipeIndex, webItem.list.length, 6)
              "
              :style="{ backgroundImage: `url(` + pic.imgURL + `)` }"
              @click="onTopicClick(pic.id)"
            >
              <div class="text">
                <div class="text-con">
                  <div class="name">{{ pic.title }}</div>
                  <div class="main-text">{{ pic.mainText }}</div>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
      <div :class="'fts-model-' + webItem.model" v-else>
        <div
          class="post-con"
          v-for="(topic, topicIndex) in webItem.list"
          :key="'fts_' + topicIndex"
          @click="onTopicClick(topic.id)"
        >
          <div
            v-if="webItem.model == 'm_12'"
            class="post-img"
            :style="{
              backgroundImage:
                `url(` + (topicIndex == 0 ? topic.mImgURL : topic.imgURL) + `)`,
            }"
          ></div>
          <div
            v-else
            class="post-img"
            :style="{ backgroundImage: `url(` + topic.imgURL + `)` }"
          ></div>
          <div class="post-text">{{ topic.name }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// <FTS :content="content" :logined="loginedStatus" :internalView="internalShow"></FTS>
// model 2222 212 2222D 2222D is for data use，first 4 need login
import RD from "@/api/RD";

export default {
  name: "Index1FTS",
  components: {},
  props: {
    content: {
      type: Object,
      default: function () {
        return {};
      },
    },
    logined: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
    internalView: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
  },
  data: function () {
    return {
      webItem: {},
      swipeIndex: 0,
      viewList: [
        { name: "记忆吴江", id: 1, dis: false, url: "fixTopic.html?id=252" },
        {
          id: 2,
          name: "美丽吴江",
          dis: false,
          url: "fixTopic.html?id=254",
          subs: [
            { name: "春", id: 1, url: "" },
            { name: "夏", id: 2, url: "" },
            { name: "秋", id: 3, url: "" },
            { name: "冬", id: 4, url: "" },
          ],
        },
        { id: 3, name: "人文吴江", dis: false, url: "fixTopic.html?id=255" },
      ],
    };
  },
  created() {
    this.reload();
  },
  methods: {
    reload() {
      if (this.content.model == "2222D" || this.content.model == "m_111") {
        this.content.list.forEach((topic) => {
          topic.title = topic.name;
          topic.imgURL = RD.fixedTopic().id(topic.id).headPic_URL();
          topic.toTopic = true;
        });
      } else if (
        this.content.model == "m_1122" ||
        this.content.model == "m_12"
      ) {
        this.content.list.forEach((topic) => {
          topic.title = topic.name;
          topic.imgURL = RD.pic().id(topic.posts[0].frontPicId).sImage_URL();
          topic.mImgURL = RD.pic().id(topic.posts[0].frontPicId).mImage_URL();
          topic.toTopic = false;
          topic.postId = topic.posts[0].postId;
        });
      } else if (this.content.model == "m_top") {
        this.content.list.forEach((topic) => {
          topic.title = topic.name;
          topic.imgURL = RD.pic().id(topic.posts[0].frontPicId).sImage_URL();
          topic.mImgURL = RD.pic().id(topic.posts[0].frontPicId).mImage_URL();
          topic.toTopic = false;
          topic.postId = topic.posts[0].postId;
        });
      } else {
        this.content.list.forEach((topic) => {
          if (topic.posts[0]) {
            topic.title = topic.posts[0].title;
            topic.imgURL = RD.pic().id(topic.posts[0].frontPicId).sImage_URL();
            topic.mImgURL = RD.pic().id(topic.posts[0].frontPicId).mImage_URL();
            topic.toTopic = false;
            topic.postId = topic.posts[0].postId;
          }
        });
      }
      this.webItem = this.content;
    },
    onTopicClick(topicId) {
      // if (
      //   (needLogin && !this.logined) ||
      //   (needInternalView && !this.internalView)
      // ) {
      //   this.$alert("您当前没有此栏目读取权限", "提示", {
      //     confirmButtonText: "确定",
      //     type: "warning",
      //   })
      //     .then(() => {})
      //     .catch(() => {});
      // } else {

      // }

      this.$router.push({
        path: "/fixTopic.html",
        query: { id: topicId },
      });
    },
    onTopicPostClick(postId) {
      this.$router.push({
        path: "/post.html",
        query: { id: postId },
      });
    },
    onUserMore() {
      this.$alert("栏目建设中", "提示", {
        confirmButtonText: "确定",
        type: "warning",
      })
        .then(() => {})
        .catch(() => {});
    },
    onMoreFTS(item) {
      let thiz = this;
      if (item.model == "2222D") {
        if (thiz.logined == false || thiz.internalView == false) {
          this.$alert("您当前没有读取权限", "提示", {
            confirmButtonText: "确定",
            type: "warning",
          })
            .then(() => {})
            .catch(() => {});
        } else if (thiz.internalView == true && thiz.logined == true) {
          this.$router.push({
            path: "/catalog.html",
            query: { title: item.catalog },
          });
        }
      } else {
        this.$router.push({
          path: "/catalog.html",
          query: { title: item.catalog },
        });
      }
    },
    /**
     * swipe filter utils, return if the target index is show or not
     * @Param targetIndex item index to test
     * @Param length array length
     * @Param swipeIndex swipePosition to test
     * @Param forwardGap how many item in the array larger than swipeIndex will be marked as valid
     * @Param backwardGap how many item in the array smaller the swipeIndex will be marked as valid
     */
    swipeFilter(t, l, s, f, b) {
      return !((((t - s) % l) + l) % l > f && (((s - t) % l) + l) % l > b);
    },
    /**
     * return the position index
     * position when swipeIndex == targetIndex return 0
     * forward return  1 2 3
     * backword return total -1 total -2 total -3
     * @Param targetIndex
     * @Param swipeIndex
     * @Param total length of array
     * @Param length of items show
     */ swipePosition(t, s, l, g) {
      return Math.abs((t - (((s % l) + l) % l) + l) % l) <=
        Math.abs(((((s % l) + l) % l) - t + l) % l)
        ? ((((t - s) % l) + l) % l) % g
        : t > ((s % l) + l) % l
        ? (((-(((s % l) + l) % l) - l + t) % g) + g) % g
        : (((-(((s % l) + l) % l) + t) % g) + g) % g;
    },
  },
};
</script>

<style lang="less" scoped>
// .camera-icon {
//   background: no-repeat 50% / contain url(../../assets/camera.png);
// }
.post-con {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  .post-img {
    flex: 1;
    width: 100%;
    background: no-repeat 50% / cover;
    transition: all 0.2s ease-in-out;
  }
  .post-text {
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

//common part

.web-item {
  width: 120rem;
  height: auto;
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  .web-item-header {
    width: 100%;
    text-align: left;
    position: relative;
    .web-item-icon {
      position: absolute;
    }
    .web-item-title {
      // position: absolute;
      text-align: left;
    }
    .web-item-more {
      position: absolute;
      color: #878787;
      cursor: pointer;
    }
    .web-item-header-gap {
      position: absolute;
      background: #bfbfbf;
    }
  }
  .web-item-content {
    .fts-model-m_212 {
      overflow: hidden;
      width: 100%;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;
      position: relative;
      .post-con {
        // position: absolute;
        .post-img {
          height: 100%;
        }
        .post-text {
          width: 100%;
        }
      }

      .post-con:nth-child(1) {
        top: 0;
      }
      .post-con:nth-child(2) {
        bottom: 0px;
        left: 0;
      }
      .post-con:nth-child(3) {
        bottom: 0;
        left: 0;
      }
      .post-con:nth-child(4) {
        top: 0;
        right: 0;
      }
      .post-con:nth-child(5) {
        bottom: 0;
        right: 0;
      }
      .post-con:nth-child(1n + 6) {
        display: none;
      }
    }
    .fts-model-m_2222 {
      overflow: hidden;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      flex-wrap: wrap;
      position: relative;
      .post-con {
        position: relative;
        .post-img {
          height: 100%;
        }
        .post-text {
          position: absolute;
          left: 0px;
          bottom: 0rem;
          width: 20rem;
          height: 4rem;
          color: #fff;
          background-color: rgba(0, 0, 0, 0.3);
        }
      }

      .post-con:nth-child(9 + n) {
        display: none;
      }
    }

    .fts-model-m_12 {
      height: 51rem;
      position: relative;
      overflow: hidden;
      width: 120rem;
      margin: 0 auto;

      .post-con {
        position: absolute;
        overflow: hidden;
        .post-img {
          height: 90%;
          overflow: hidden;
          transition: all 500ms ease-in-out;
          .img {
            width: 100%;
            height: 100%;
            background: no-repeat 50% / cover;
            transition: all 500ms ease-in-out;
          }
        }
        .post-text {
          width: 12rem;
          height: 5rem;
          line-height: 5rem;
          font-size: 2.5rem;
          color: #fff;
          position: absolute;
          bottom: 0px;
          left: 0px;
          transition: all 200ms ease-in-out;
          // background: rgba(215, 20, 12, 0.75);
          background-color: rgba(0, 0, 0, 0.3);
        }
      }

      .post-con:nth-child(1) {
        width: 84rem;
        height: 48.5rem;
        top: 0;
        left: 0;
      }

      .post-con:nth-child(2) {
        width: 34rem;
        height: 23.2rem;
        top: 0;
        right: 0;
      }

      .post-con:nth-child(3) {
        width: 34rem;
        height: 23rem;
        bottom: 2.6rem;
        right: 0;
      }

      .post-con:nth-child(1n + 4) {
        display: none;
      }
      .post-con:hover {
        .post-img {
          transform: scale(1.05);
        }
        .img {
          transform: scale(1.05);
        }
      }
    }
  }
}

@media all and (max-width: 800px) {
  .web-item {
    width: 750px;
    margin-bottom: 1rem;
    margin-top: 20px;
    .web-item-header {
      height: 50px;
      line-height: 50px;
      margin-top: 10px;
      .web-item-icon {
        width: 5rem;
        height: 5rem;
        left: 0;
        top: 1.4rem;
      }
      .web-item-title {
        width: 110px;
        height: 40px;
        line-height: 40px;
        font-size: 24px;
      }
      .item-subtitle {
        font-size: 20px;
        color: #aaa;
        height: 36px;
        margin-left: 10px;
        font-weight: lighter;
        font-family: "Microsoft Yahei", "Simsun";
        position: relative;
        .item-red {
          position: absolute;
          bottom: 0px;
          left: 50%;
          width: 80%;
          transform: translate(-50%);
          height: 3px;
          background-color: #d42d26;
        }
        .item {
          font-size: 16px;
          line-height: 36px;
        }
      }
      .web-item-more {
        right: 1.5rem;
        top: 5px;
        font-size: 15px;
      }
      .web-item-header-gap {
        top: 6.5rem;
        width: 120rem;
        height: 0.2rem;
      }
    }
    .web-item-content {
      .fts-model-212 {
        height: 44rem;
        margin-top: 3rem;
        .post-con {
          .post-text {
            height: 3rem;
            line-height: 3rem;
            font-size: 1.3rem;
          }
        }

        .post-con:nth-child(1) {
          width: 57.4rem;
          height: 44.4rem;
          top: 0;
          left: 31.5rem;
        }
        .post-con:nth-child(2),
        .post-con:nth-child(3),
        .post-con:nth-child(4),
        .post-con:nth-child(5) {
          width: 27.4rem;
          height: 21.7rem;
        }
      }
      .fts-model-m_22 {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        height: auto;
        overflow: hidden;
        width: 750px;
        margin: 0 auto;
        .post-con {
          width: 370px;
          height: 30rem;
          margin-bottom: 2rem;
          position: relative;
          overflow: hidden;
          .post-img {
            height: 100%;
            width: 100%;
            background-size: cover;
            cursor: pointer;
          }

          .post-text {
            font-size: 1.5rem;
            position: absolute;
            left: 0rem;
            bottom: 0px;
            color: #fff;
            height: 5rem;
            width: 10rem;
            line-height: 5rem;
            padding: 0 1rem;
            font-size: 2.5rem;
            font-weight: bold;
            background-color: rgba(0, 0, 0, 0.3);
            // background: hsla(0, 0%, 100%, 0.1);
            // -webkit-backdrop-filter: blur(2px);
            // backdrop-filter: blur(2px);
            transition: all 500ms ease-in-out;
            .icon {
              display: inline-block;
              width: 4.5rem;
              height: 3.5rem;
              margin-left: 22.4rem;
            }

            .culture-t {
              cursor: pointer;
            }

            div {
              height: 4.2rem;
              line-height: 4.2rem;
            }
          }
        }
        .post-con:hover {
          .post-text {
            height: 100%;
            width: 100%;
            font-size: 2.5rem;
            line-height: 30rem;
          }
        }
        .post-con:nth-child(1n + 5) {
          display: none;
        }
      }
      .fts-model-m_2222 {
        height: 50rem;
        width: calc(100% + 3rem);
        margin-top: 1rem;
        margin-left: -2.6rem;
        .post-con {
          width: 28rem;
          height: 22rem;
          margin-left: 2.6rem;
          margin-top: 2.6rem;
          position: relative;
          transition: all 500ms ease-in-out;
          .post-img {
            height: 100%;
          }
          .post-text {
            position: absolute;
            left: 0px;
            bottom: 0rem;
            width: 10rem;
            height: 4rem;
            line-height: 4rem;
            color: #fff;
            font-size: 1.8rem;
            font-weight: bold;
            background-color: rgba(0, 0, 0, 0.5);
            transition: all 500ms ease-in-out;
          }
        }
        .post-con:hover {
          .post-text {
            height: 100%;
            width: 100%;
            font-size: 2.5rem;
            line-height: 22rem;
            transition: all 500ms ease-in-out;
          }
        }
      }
      .fts-model-m_12 {
        height: 51rem;
        position: relative;
        overflow: hidden;
        width: 750px;
        margin: 0 auto;

        .post-con {
          position: absolute;
          overflow: hidden;
          .post-img {
            height: 85%;
            overflow: hidden;
            transition: all 500ms ease-in-out;
            .img {
              width: 100%;
              height: 100%;
              background: no-repeat 50% / cover;
              transition: all 500ms ease-in-out;
            }
          }
          .post-text {
            height: 20px;
            line-height: 20px;
            background-color: rgba(0, 0, 0, 0);
            color: #000;
          }
        }

        .post-con:nth-child(1) {
          width: 84rem;
          height: 294px;
          top: 0;
          left: 0;
          .post-img {
            height: 90%;
          }
        }

        .post-con:nth-child(2) {
          width: 230px;
          height: 142px;
          top: 0;
          right: 0;
        }

        .post-con:nth-child(3) {
          width: 230px;
          height: 142px;
          bottom: 15px;
          right: 0;
        }

        .post-con:nth-child(1n + 4) {
          display: none;
        }
        .post-con:hover {
          .img {
            transform: scale(1.05);
          }
        }
      }
      .fts-model-m_111 {
        height: 41.5rem;
        position: relative;
        overflow: hidden;
        width: 120rem;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;

        .post-con {
          width: 39rem;
          height: 41.1rem;
          position: relative;
          overflow: hidden;
          .post-img {
            height: 100%;
            background: no-repeat 50% / cover;
            transition: all 500ms ease-in-out;
          }
          .post-text {
            // position: absolute;
            // left: 50%;
            // top: 50%;
            // transform: translate(-50%, -50%);
            // color: #fff;
            // border: 1px solid #fff;
            // font-size: 1.6rem;
            display: none;
          }
        }

        .post-con:nth-child(1n + 4) {
          display: none;
        }

        .post-con:hover {
          .post-img {
            transform: scale(1.05);
            background-color: rgba(255, 252, 252, 0.3);
          }
        }
      }
      .fts-model-m_top {
        width: 750px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        position: relative;
        .swiper-list {
          position: relative;
          width: 100%;
          overflow: hidden;
          height: 48rem;
          gap: 10px;
          .pic {
            height: 47.2rem;
            width: 28.5rem;
            flex-shrink: 0;
            top: 0px;
            background: no-repeat 50% / cover;
            box-sizing: border-box;
            position: absolute;
            transition: 500ms left, width, top, height ease-in-out;
            cursor: pointer;
            .text {
              width: 100%;
              height: 100%;
              position: relative;
              transition: all 200ms ease-in-out;
              background: rgba(255, 255, 255, 0.2);
              backdrop-filter: blur(2px);
              border-radius: 4px;
              opacity: 0;
              .text-con {
                position: absolute;
                bottom: 0px;
                height: 9.3rem;
                width: 100%;
                background: rgba(215, 20, 12, 0.75);
                .name {
                  width: 19rem;
                  margin: 0 auto;
                  height: 5.5rem;
                  font-size: 18px;
                  color: #fff;
                  margin-top: 3.3rem;
                  overflow: hidden;
                  // text-align: left;
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-line-clamp: 5;
                  -webkit-box-orient: vertical;
                }
                .main-text {
                  width: 83%;
                  text-align: center;
                  margin: 15px auto 0px;
                  font-size: 16px;
                  color: rgba(255, 255, 255, 0.7);
                  overflow: hidden;
                  font-size: 1.4rem;
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-line-clamp: 3;
                  -webkit-box-orient: vertical;
                }
              }
            }
          }
          .pic.pos-0 {
            left: 0rem;
          }
          .pic.pos-1 {
            left: 30.5rem;
          }
          .pic.pos-2 {
            left: 61rem;
          }
          // .pic.pos-3 {
          //   left: 90rem;
          // }
          .pic.pos-3 {
            left: 92rem;
          }
          .pic.pos-4 {
            left: 120rem;
          }
          .pic.pos-5 {
            left: 91.5rem;
          }
          // .pic.pos-5 {
          //   top: -1200rem;
          //   left: 120rem;
          //   width: 1px;
          //   height: 1px;
          // }
          .pic.pos-6 {
            top: -1200rem;
            left: -30rem;
            width: 1px;
            height: 1px;
          }
          .pic.pos-7 {
            left: -30rem;
          }
          .pic:hover {
            border: 3px solid rgba(215, 20, 12, 0.75);
            .text {
              opacity: 1;
            }
          }
        }

        .swiper-left {
          position: absolute;
          left: -7rem;
          top: 40%;
          cursor: pointer;
          .left {
            width: 6.4rem;
            height: 6.4rem;
            background: rgba(0, 0, 0, 0.25);
            position: relative;
            .left-icon {
              height: 2.5rem;
              width: 2.5rem;
              border-top: 0.3rem solid #dcdcdd;
              border-left: 0.3rem solid #dcdcdd;
              transform: rotate(-45deg);
              position: absolute;
              top: 2rem;
              left: 2.5rem;
            }
          }
        }
        .swiper-right {
          position: absolute;
          right: -7rem;
          top: 40%;
          cursor: pointer;
          .right {
            width: 6.4rem;
            height: 6.4rem;
            background: rgba(0, 0, 0, 0.25);
            position: relative;
            .right-icon {
              height: 2.5rem;
              width: 2.5rem;
              border-bottom: 0.3rem solid #dcdcdd;
              border-right: 0.3rem solid #dcdcdd;
              transform: rotate(-45deg);
              position: absolute;
              top: 2rem;
              right: 2.5rem;
            }
          }
        }
      }
      .fts-model-m_212 {
        height: 50rem;
        margin: 3rem 0rem 5rem;
        .post-con {
          position: absolute;
          .post-text {
            position: absolute;
            bottom: 0px;
            left: 0px;
            height: 5rem;
            line-height: 5rem;
            font-size: 2.5rem;
            color: #fff;
            background-color: rgba(0, 0, 0, 0.3);
            transition: all 400ms ease-in-out;
          }
        }

        .post-con:nth-child(2) {
          width: 57.4rem;
          height: 50.4rem;
          top: 0;
          left: 31.5rem;
        }
        .post-con:nth-child(1),
        .post-con:nth-child(3),
        .post-con:nth-child(4),
        .post-con:nth-child(5) {
          width: 29rem;
          height: 23.7rem;
        }
        .post-con:hover {
          .post-text {
            height: 100%;
            width: 100%;
            font-size: 2.5rem;
            line-height: 23.7rem;
          }
        }
        .post-con:nth-child(2):hover {
          .post-text {
            line-height: 50.4rem;
          }
        }
        .post-con:nth-child(1n + 6) {
          display: none;
        }
      }
    }
  }
}
@media all and (min-width: 801px) {
  .web-item {
    width: 120rem;
    margin-bottom: 1rem;
    .web-item-header {
      height: 7rem;
      line-height: 7rem;
      .web-item-icon {
        width: 5rem;
        height: 5rem;
        left: 0;
        top: 1.4rem;
      }
      .web-item-title {
        // top: 2rem;
        // left: 5.5rem;
        width: 20rem;
        height: 4rem;
        line-height: 4rem;
        font-size: 2rem;
      }
      .item-subtitle {
        font-size: 20px;
        color: #aaa;
        height: 6rem;
        margin-left: 10px;
        font-weight: lighter;
        font-family: "Microsoft Yahei", "Simsun";
        position: relative;
        .item-red {
          position: absolute;
          bottom: 8px;
          left: 50%;
          width: 70%;
          transform: translate(-50%);
          height: 3px;
          background-color: #d42d26;
        }
      }
      .web-item-more {
        right: 1.5rem;
        top: 1.5rem;
        font-size: 1.3rem;
      }
      .m_2222-title {
        position: absolute;
        right: 0px;
        top: 20px;
        width: 38rem;
        display: flex;
        z-index: 999;
        .view-item {
          width: 64px;
          text-align: right;
          position: relative;
          margin-right: 10px;
          padding: 0 24px;
          height: 38px;
          line-height: 38px;
          border: 1px solid #ff8c00;
          border-radius: 18px;
          font-size: 16px;
          font-weight: bold;
          color: #ff8c00;
          .view {
            width: 100%;
            height: 100%;
            position: relative;
            .view-name {
              width: 100%;
              height: 100%;
              cursor: pointer;
              a {
                display: inline-block;
                width: 100%;
                color: #ff8c00;
              }
            }
            .subs {
              display: none;
              width: 112px;
              height: 192px;
              position: absolute;
              top: 39px;
              left: -23px;
              .subs-item {
                padding: 0 24px;
                height: 36px;
                line-height: 36px;
                border: 1px solid #ff8c00;
                border-radius: 18px;
                font-size: 16px;
                font-weight: bold;
                background-color: #fff;
                margin-top: 10px;
                cursor: pointer;
                a {
                  color: #ff8c00;
                }
              }
            }
          }
        }
        .primary {
          background: #ff8c00;
          color: #fff;
          .view {
            .view-name {
              a {
                color: #fff;
              }
            }
          }
        }
        .view-item:hover {
          background: #ff8c00;
          color: #fff;
          .view {
            .view-name {
              a {
                color: #fff;
              }
            }
            .subs {
              display: block;
              width: 112px;
              text-align: center;
              color: #ff8c00;
              .subs-item:hover {
                background: #ff8c00;
                color: #fff;
                a {
                  color: #fff;
                }
              }
            }
          }
        }
      }
      .web-item-header-gap {
        top: 6.5rem;
        width: 120rem;
        height: 0.2rem;
      }
    }
    .model_m_2222 {
      margin-bottom: 0px !important;
    }
    .web-item-content {
      .fts-model-212 {
        height: 44rem;
        margin-top: 3rem;
        .post-con {
          .post-text {
            height: 3rem;
            line-height: 3rem;
            font-size: 1.3rem;
          }
        }

        .post-con:nth-child(1) {
          width: 57.4rem;
          height: 44.4rem;
          top: 0;
          left: 31.5rem;
        }
        .post-con:nth-child(2),
        .post-con:nth-child(3),
        .post-con:nth-child(4),
        .post-con:nth-child(5) {
          width: 27.4rem;
          height: 21.7rem;
        }
      }
      .fts-model-m_22 {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        height: auto;
        overflow: hidden;
        width: 120rem;
        margin: 0 auto;
        .post-con {
          width: 59rem;
          height: 30rem;
          margin-bottom: 2rem;
          position: relative;
          overflow: hidden;
          .post-img {
            height: 100%;
            width: 100%;
            background-size: cover;
            cursor: pointer;
          }

          .post-text {
            font-size: 1.5rem;
            position: absolute;
            left: 0rem;
            bottom: 0px;
            color: #fff;
            height: 5rem;
            width: 10rem;
            line-height: 5rem;
            padding: 0 1rem;
            font-size: 2.5rem;
            // font-weight: bold;
            background-color: rgba(0, 0, 0, 0.3);
            // background: hsla(0, 0%, 100%, 0.1);
            // -webkit-backdrop-filter: blur(2px);
            // backdrop-filter: blur(2px);
            transition: all 500ms ease-in-out;
            .icon {
              display: inline-block;
              width: 4.5rem;
              height: 3.5rem;
              margin-left: 22.4rem;
            }

            .culture-t {
              cursor: pointer;
            }

            div {
              height: 4.2rem;
              line-height: 4.2rem;
            }
          }
        }
        .post-con:hover {
          .post-text {
            height: 100%;
            width: 100%;
            font-size: 2.5rem;
            line-height: 30rem;
          }
        }
        .post-con:nth-child(1n + 5) {
          display: none;
        }
      }
      .fts-model-m_2222 {
        height: 50rem;
        width: calc(100% + 3rem);
        margin-top: 0.5rem;
        margin-left: -2.6rem;
        .post-con {
          width: 28rem;
          height: 22rem;
          margin-left: 2.6rem;
          margin-top: 2.6rem;
          position: relative;
          transition: all 500ms ease-in-out;
          .post-img {
            height: 100%;
          }
          .post-text {
            position: absolute;
            left: 0px;
            bottom: 0rem;
            width: 10rem;
            height: 4rem;
            line-height: 4rem;
            color: #fff;
            font-size: 1.8rem;
            font-weight: bold;
            background-color: rgba(0, 0, 0, 0.5);
            transition: all 500ms ease-in-out;
          }
        }
        .post-con:hover {
          .post-text {
            height: 100%;
            width: 100%;
            font-size: 2.5rem;
            line-height: 22rem;
            transition: all 500ms ease-in-out;
          }
        }
      }
      .fts-model-m_111 {
        height: 41.5rem;
        position: relative;
        overflow: hidden;
        width: 120rem;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;

        .post-con {
          width: 39rem;
          height: 41.1rem;
          position: relative;
          overflow: hidden;
          .post-img {
            height: 100%;
            background: no-repeat 50% / cover;
            transition: all 500ms ease-in-out;
          }
          .post-text {
            // position: absolute;
            // left: 50%;
            // top: 50%;
            // transform: translate(-50%, -50%);
            // color: #fff;
            // border: 1px solid #fff;
            // font-size: 1.6rem;
            display: none;
          }
        }

        .post-con:nth-child(1n + 4) {
          display: none;
        }

        .post-con:hover {
          .post-img {
            transform: scale(1.05);
            background-color: rgba(255, 252, 252, 0.3);
          }
        }
      }

      .fts-model-m_1122 {
        overflow: hidden;
        width: 120rem;
        margin: 0 auto;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        position: relative;
        height: 60rem;
        .post-con {
          position: absolute;
          width: 30rem;
          height: 60rem;
          overflow: hidden;
          .post-img {
            height: 100%;
          }
          .post-text {
            position: absolute;
            bottom: 0px;
            left: 0px;
            width: 0px;
            height: 0px;
            transition: all 500ms ease-in-out;
          }
        }
        .post-con:hover {
          .post-text {
            width: 98%;
            height: 100%;
            line-height: 60rem;
            color: #fff;
            font-size: 2rem;
            padding: 0 10px;
            background-color: rgba(0, 0, 0, 0.3);
          }
        }
        .post-con:nth-child(3):hover {
          .post-text {
            line-height: 29rem;
          }
        }
        .post-con:nth-child(4):hover {
          .post-text {
            line-height: 29rem;
          }
        }

        .post-con:nth-child(1) {
          top: 0;
        }
        .post-con:nth-child(2) {
          top: 0;
          width: 56rem;
          left: 32rem;
        }
        .post-con:nth-child(3) {
          top: 0;
          right: 0;
          height: 29rem;
        }
        .post-con:nth-child(4) {
          bottom: 0rem;
          right: 0;
          height: 29rem;
        }
        .post-con:nth-child(5) {
          bottom: 0;
          right: 0;
        }
        .post-con:nth-child(1n + 6) {
          display: none;
        }
      }

      .fts-model-m_top {
        width: 120rem;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        position: relative;
        .swiper-list {
          position: relative;
          width: 100%;
          overflow: hidden;
          height: 48rem;
          gap: 10px;
          .pic {
            height: 47.2rem;
            width: 28.5rem;
            flex-shrink: 0;
            top: 0px;
            background: no-repeat 50% / cover;
            box-sizing: border-box;
            position: absolute;
            transition: 500ms left, width, top, height ease-in-out;
            cursor: pointer;
            .text {
              width: 100%;
              height: 100%;
              position: relative;
              transition: all 200ms ease-in-out;
              background: rgba(255, 255, 255, 0.2);
              backdrop-filter: blur(2px);
              border-radius: 4px;
              opacity: 0;
              .text-con {
                position: absolute;
                bottom: 0px;
                height: 9.3rem;
                width: 100%;
                background: rgba(215, 20, 12, 0.75);
                .name {
                  width: 19rem;
                  margin: 0 auto;
                  height: 5.5rem;
                  font-size: 18px;
                  color: #fff;
                  margin-top: 3.3rem;
                  overflow: hidden;
                  // text-align: left;
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-line-clamp: 5;
                  -webkit-box-orient: vertical;
                }
                .main-text {
                  width: 83%;
                  text-align: center;
                  margin: 15px auto 0px;
                  font-size: 16px;
                  color: rgba(255, 255, 255, 0.7);
                  overflow: hidden;
                  font-size: 1.4rem;
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-line-clamp: 3;
                  -webkit-box-orient: vertical;
                }
              }
            }
          }
          .pic.pos-0 {
            left: 0rem;
          }
          .pic.pos-1 {
            left: 30.5rem;
          }
          .pic.pos-2 {
            left: 61rem;
          }
          // .pic.pos-3 {
          //   left: 90rem;
          // }
          .pic.pos-3 {
            left: 92rem;
          }
          .pic.pos-4 {
            left: 120rem;
          }
          .pic.pos-5 {
            left: 91.5rem;
          }
          // .pic.pos-5 {
          //   top: -1200rem;
          //   left: 120rem;
          //   width: 1px;
          //   height: 1px;
          // }
          .pic.pos-6 {
            top: -1200rem;
            left: -30rem;
            width: 1px;
            height: 1px;
          }
          .pic.pos-7 {
            left: -30rem;
          }
          .pic:hover {
            border: 3px solid rgba(215, 20, 12, 0.75);
            .text {
              opacity: 1;
            }
          }
        }

        .swiper-left {
          position: absolute;
          left: -7rem;
          top: 40%;
          cursor: pointer;
          .left {
            width: 6.4rem;
            height: 6.4rem;
            background: rgba(0, 0, 0, 0.25);
            position: relative;
            .left-icon {
              height: 2.5rem;
              width: 2.5rem;
              border-top: 0.3rem solid #dcdcdd;
              border-left: 0.3rem solid #dcdcdd;
              transform: rotate(-45deg);
              position: absolute;
              top: 2rem;
              left: 2.5rem;
            }
          }
        }
        .swiper-right {
          position: absolute;
          right: -7rem;
          top: 40%;
          cursor: pointer;
          .right {
            width: 6.4rem;
            height: 6.4rem;
            background: rgba(0, 0, 0, 0.25);
            position: relative;
            .right-icon {
              height: 2.5rem;
              width: 2.5rem;
              border-bottom: 0.3rem solid #dcdcdd;
              border-right: 0.3rem solid #dcdcdd;
              transform: rotate(-45deg);
              position: absolute;
              top: 2rem;
              right: 2.5rem;
            }
          }
        }
      }

      .fts-model-m_212 {
        height: 50rem;
        margin: 3rem 0rem 5rem;
        .post-con {
          position: absolute;
          .post-text {
            position: absolute;
            bottom: 0px;
            left: 0px;
            height: 5rem;
            line-height: 5rem;
            font-size: 2.5rem;
            color: #fff;
            background-color: rgba(0, 0, 0, 0.3);
            transition: all 400ms ease-in-out;
          }
        }

        .post-con:nth-child(3) {
          width: 57.4rem;
          height: 50.4rem;
          top: 0;
          left: 31.5rem;
        }
        .post-con:nth-child(1),
        .post-con:nth-child(2),
        .post-con:nth-child(4),
        .post-con:nth-child(5) {
          width: 29rem;
          height: 23.7rem;
        }
        .post-con:hover {
          .post-text {
            height: 100%;
            width: 100%;
            font-size: 2.5rem;
            line-height: 23.7rem;
          }
        }
        .post-con:nth-child(2):hover {
          .post-text {
            line-height: 50.4rem;
          }
        }
        .post-con:nth-child(1n + 6) {
          display: none;
        }
      }
    }
  }
}
</style>
