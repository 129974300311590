<template>
  <div class="web-item">
    <div class="web-item-header">
      <div class="web-item-title">{{ webItem.title }}</div>
      <div class="web-item-subtitle">{{ webItem.subTitle }}</div>
    </div>
    <div class="link-con">
      <div class="link-list">
        <div
          class="link-detail"
          v-for="p in webItem.list"
          :key="'link-detail' + p.id"
          v-bind:class="{
            transformLeft: webItem.isleft,
          }"
        >
          <a :href="p.link" target="_blank">{{ p.name }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RD from "@/api/RD";

export default {
  name: "IndexLink",
  components: {},
  props: {
    content: {
      type: Object,
      default: function () {
        return {};
      },
    },
    logined: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
  },
  data: function () {
    return { webItem: {} };
  },
  created() {
    this.reload();
  },
  methods: {
    reload() {
      setTimeout(() => {
        this.content.list.forEach((i) => {
          if (i.id) {
            i.imgURL = RD.exchange().id(i.id).headPic_URL();
          }
        });
        this.webItem = this.content;
      }, 1000);
    },
  },
};
</script>

<style lang="less" scoped>
@media all and (max-width: 800px) {
  .web-item {
    width: 750px;
    margin: 0 auto;

    .link-title {
      height: 50px;
      display: flex;
      position: relative;
      margin-left: 50px;

      .link-name {
        width: 140px;
        text-align: left;
        font-size: 22px;
        margin-top: 15px;
      }

      .clip {
        position: absolute;
        left: 93px;
        top: 38px;
        width: 89.25rem;
        height: 0.85rem;
        background: #d9d9d9;
      }
    }

    .link-con {
      overflow: hidden;
      width: 700px;
      margin-left: 50px;

      .link-list {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        flex-wrap: wrap;

        .link-detail {
          width: auto;
          height: 38px;
          line-height: 5.5rem;
          margin: 0.42rem 1.25rem 0.42rem 0px;
          padding: 0.42rem 1.25rem 0.42rem 0px;

          a {
            height: 100%;
            width: 100%;
            display: inline-block;
            text-decoration: none;
            color: #000;
            font-size: 1.3rem;
          }
        }

        .transformLeft {
          transform: translateX(-54rem);
          transition: all 800ms ease-in-out;
        }
      }
    }
  }
}
@media all and (min-width: 801px) {
  .web-item-header {
    .web-item-subtitle {
      font-size: 22px;
      margin-left: 10px;
      font-weight: lighter;
      color: #aaaaaa;
      vertical-align: bottom;
      font-family: "Microsoft Yahei", "Simsun";
    }
  }
  .web-item {
    width: 120rem;
    margin: 0 auto;
    border-bottom: 1px solid #ccc;
    .link-title {
      height: 6.7rem;
      display: flex;
      position: relative;

      .link-name {
        width: 140px;
        text-align: left;
        font-size: 2.2rem;
        margin-top: 1.7rem;
      }

      .clip {
        position: absolute;
        left: 10.6rem;
        top: 3.9rem;
        width: 89.25rem;
        height: 0.85rem;
        background: #d9d9d9;
      }
    }

    .link-con {
      overflow: hidden;
      padding: 0px 0px 70px 0;
      .link-list {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        flex-wrap: wrap;

        .link-detail {
          width: auto;
          height: 5.5rem;
          line-height: 5.5rem;
          margin: 0.42rem 1.25rem 0.42rem 0px;
          padding: 0.42rem 1.25rem 0.42rem 0px;

          a {
            height: 100%;
            width: 100%;
            display: inline-block;
            text-decoration: none;
            color: #000;
            font-size: 1.3rem;
          }
        }

        .transformLeft {
          transform: translateX(-54rem);
          transition: all 800ms ease-in-out;
        }
      }
    }
  }
}
</style>

