<template>
  <div class="spe" :class="'model_' + content.model">
    <div class="spe-con">
      <div class="spe-mix22" v-if="this.content.model == 'm_spe_22'">
        <div class="mix-left">
          <div class="left-name">精彩图库</div>
          <div class="left-con">
            <div class="local">
              <div class="local-img"></div>
              <div class="local-text">
                <a href="local.html" target="_blank"><span>区镇> ></span></a>
              </div>
            </div>
            <div class="sheyingjidi">
              <div class="sheying-img"></div>
              <div class="sheying-text">
                <a href="" target="_blank"><span>摄影基地> ></span></a>
              </div>
            </div>
          </div>
        </div>
        <div class="mix-right">
          <div class="right-name">
            <div class="r-name">赛事活动</div>
            <div class="r-more">
              <a href="" target="_blank">更多></a>
            </div>
          </div>
          <div class="activity-list">
            <div class="ac-item" v-for="k in activityList" :key="'ac_' + k.id">
              <div
                class="ac-img"
                :style="{ backgroundImage: `url(` + k.imgURL + `)` }"
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div class="spe-mix211" v-if="this.content.model == 'm_spe_211'">
        <div class="mix-left">
          <div class="left-name">艺影长廊 <span>Art</span></div>
          <div class="left-con">
            <div class="one">
              <a href="fixTopic.html?id=17" target="_blank">
                <div>精彩视频</div>
              </a>
            </div>
            <div class="two">
              <a href="userShow.html" target="_blank">
                <div>摄影师</div>
              </a>
            </div>
          </div>
        </div>
        <!-- <div class="mix-right">
          <div class="right-one">
            <div class="name">增值服务<span>Services</span></div>
            <div class="image">
              <a href="" target="_blank">
                <div>摄影艺术</div>
              </a>
            </div>
          </div>
          <div class="right-two">
            <div class="name">精彩视频<span>Videos</span></div>
            <div class="video" v-if="videoList[0]">
              <div
                :style="{
                  backgroundImage: `url(` + videoList[0].imageURL + `)`,
                }"
              ></div>
              <div class="video-icon"></div>
            </div>
          </div>
        </div> -->
      </div>

      <div class="spe-mix111" v-if="this.content.model == 'm_spe_111'">
        <div class="spe-mix111-title">
          <div class="title">{{ this.content.title }}</div>
          <div class="subtitle">{{ this.content.subTitle }}</div>
        </div>
        <div class="spe-mix111-con">
          <div class="one">
            <div class="img">
              <a href="/fixTopic.html?id=264" target="_blank"></a>
            </div>
          </div>
          <div class="two">
            <div class="img">
              <a href="/fixTopic.html?id=261" target="_blank"></a>
            </div>
          </div>
          <div class="three">
            <div class="img">
              <a href="/fixTopic.html?id=263" target="_blank"></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RD from "@/api/RD";

export default {
  name: "IndexSPE",
  components: {},
  props: {
    content: {
      type: Object,
      default: function () {
        return {};
      },
    },
    logined: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
  },
  data: function () {
    return {
      webItem: {},
      activityList: [
        {
          id: 1,
          imgURL: "https://www.ahtpw.com/attachment/wutu/index/video/1.png",
        },
        {
          id: 2,
          imgURL: "https://www.ahtpw.com/attachment/wutu/index/video/2.png",
        },
      ],
      videoList: [
        {
          id: 1,
          imageURL:
            "https://www.ahtpw.com/attachment/wutu/video/9月23日江村研讨会.mp4.jpg",
        },
      ],
    };
  },
  created() {
    this.reload();
  },
  methods: {
    reload() {
      console.log("spe", this.content);
      setTimeout(() => {
        this.webItem = this.content;
        var thiz = this;

        // thiz.activityList = thiz.$rtm.store.activityList;
        RD.pure()
          .fixedTopic()
          .id(100)
          .posts(thiz.page, 20)
          .then((data) => {
            data.list.forEach((k) => {
              k.imageURL = RD.video().id(k.id).poster_URL();
            });
            this.videoList = data.list;
          });
      }, 1000);
    },
    // 打开活动详情页
    onActivity(acId) {
      this.$router.push({
        path: "/activityDetail.html",
        query: { code: acId },
      });
    },
    onVideoFixtopic(videoId) {
      this.$router.push({
        path: "/videoTopic.html",
        query: { id: videoId },
      });
    },
  },
};
</script>

<style lang="less" scoped>
@media all and (max-width: 800px) {
  .spe {
    .model_m_spe_211 {
      .spe-con {
        .spe-mix211 {
          width: 750px;
          margin: 0 auto;
          display: flex;
          height: 100%;
          padding-bottom: 5rem;
          .mix-left {
            width: 100%;
            margin-top: 5.5rem;
            .left-name {
              text-align: left;
              font-size: 24px;
              span {
                font-size: 20px;
                margin-left: 10px;
                font-weight: lighter;
                color: #aaaaaa;
                vertical-align: bottom;
                font-family: "Microsoft Yahei", "Simsun";
              }
            }
            .left-con {
              margin-top: 30px;
              display: flex;
              .one,
              .two {
                width: 50%;
                height: 220px;
                position: relative;
                margin-right: 10px;
                cursor: pointer;
                div {
                  position: absolute;
                  bottom: 0px;
                  left: 0px;
                  height: 43px;
                  line-height: 43px;
                  width: 100%;
                  color: #fff;
                  font-size: 18px;
                  background: rgba(0, 0, 0, 0.6);
                }
                a {
                  display: inline-block;
                  width: 29rem;
                  height: 22rem;
                  color: #fff;
                }
              }
              .one {
                background: no-repeat url(../../assets/t3.png);
                background-size: cover;
              }
              .two {
                background: no-repeat url(../../assets/t1.png);
                background-size: cover;
              }
            }
          }
          .mix-right {
            display: flex;
            width: 50%;
            margin-top: 5.5rem;
            .right-one,
            .right-two {
              width: 50%;
              height: 220px;
              .name {
                text-align: left;
                font-size: 34px;
                cursor: pointer;
                span {
                  font-size: 22px;
                  margin-left: 10px;
                  font-weight: lighter;
                  color: #aaaaaa;
                  vertical-align: bottom;
                  font-family: "Microsoft Yahei", "Simsun";
                }
              }
            }
            .right-one {
              .image {
                width: 300px;
                height: 220px;
                // background: no-repeat url(../../assets/t2.jpg);
                background-size: cover;
                margin-top: 30px;
                position: relative;
                div {
                  position: absolute;
                  bottom: 0px;
                  left: 0px;
                  height: 43px;
                  line-height: 43px;
                  width: 100%;
                  color: #fff;
                  font-size: 18px;
                  background: rgba(0, 0, 0, 0.6);
                }
                a {
                  display: inline-block;
                  width: 290px;
                  height: 220px;
                }
              }
            }
            .right-two {
              margin-left: 10px;
              .video {
                height: 220px;
                margin-top: 30px;
                background-color: #000;
                position: relative;
                div {
                  height: 100%;
                  background: no-repeat 50% / contain;
                }
                .video-icon {
                  position: absolute;
                  top: 31%;
                  left: 34%;
                  width: 100px;
                  height: 100px;
                  background: no-repeat url(../../assets/videoplay.png);
                  background-size: cover;
                }
              }
            }
          }
        }
      }
    }
  }
}
@media all and (min-width: 801px) {
  .spe {
    width: 100%;
    margin: 20px auto;
    .spe-con {
      height: auto;
      width: 100%;
      .spe-mix22 {
        width: 120rem;
        margin: 0 auto;
        display: flex;
        height: 100%;
        padding-bottom: 5rem;
        .mix-left {
          width: 50%;
          height: 28.4rem;
          margin-top: 5.5rem;
          .left-name {
            text-align: left;
            height: 3.4rem;
            font-size: 3rem;
            margin-bottom: 3rem;
          }
          .left-con {
            display: flex;
            .local,
            .sheyingjidi {
              height: 22rem;
              width: 50%;
              position: relative;
              margin-right: 1rem;
              .local-img {
                height: 100%;
                // background: no-repeat url(../../assets/jc1.jpg);
                background-size: cover;
              }
              .sheying-img {
                height: 100%;
                // background: no-repeat url(../../assets/jc2.jpg);
                background-size: cover;
              }
              .local-text,
              .sheying-text {
                position: absolute;
                bottom: 0px;
                left: 0px;
                width: 100%;
                height: 4rem;
                line-height: 4rem;
                background-color: rgba(0, 0, 0, 0.5);
                color: #fff;
                font-size: 1.8rem;
                a {
                  display: inline-block;
                  width: 100%;
                  height: 100%;
                  color: #fff;
                  span {
                    position: absolute;
                    right: 1rem;
                    bottom: 0px;
                    text-align: right;
                    margin-right: 1rem;
                  }
                }
              }
            }
          }
        }
        .mix-right {
          width: 50%;
          height: 28.4rem;
          margin-top: 5.5rem;
          .right-name {
            display: flex;
            position: relative;
            .r-name {
              text-align: left;
              height: 3.4rem;
              font-size: 3rem;
              margin-bottom: 3rem;
            }
            .r-more {
              position: absolute;
              right: 0px;
              top: 16px;
              font-size: 1.5rem;
              a {
                display: inline-block;
                width: 100%;
                height: 100%;
                color: grey !important;
                font-size: 20px;
                font-weight: lighter;
              }
            }
          }
          .activity-list {
            display: flex;
            justify-content: space-between;
            overflow: hidden;
            gap: 10px;
            .ac-item {
              width: 30rem;
              height: 22rem;
              flex-shrink: 0;
              .ac-img {
                width: 100%;
                height: 100%;
                background: no-repeat 50% / cover;
                cursor: pointer;
              }
            }
            .ac-item:nth-child(2 + n) {
              display: none;
            }
          }
        }
      }
      .spe-mix211 {
        width: 120rem;
        margin: 0 auto;
        display: flex;
        height: 100%;
        padding-bottom: 5rem;
        .mix-left {
          width: 100%;
          margin-top: 5.5rem;
          .left-name {
            text-align: left;
            font-size: 34px;
            span {
              font-size: 22px;
              margin-left: 10px;
              font-weight: lighter;
              color: #aaaaaa;
              vertical-align: bottom;
              font-family: "Microsoft Yahei", "Simsun";
            }
          }
          .left-con {
            margin-top: 30px;
            display: flex;
            .one,
            .two {
              width: 600px;
              height: 440px;
              position: relative;
              margin-right: 10px;
              cursor: pointer;
              div {
                position: absolute;
                bottom: 0px;
                left: 0px;
                height: 43px;
                line-height: 43px;
                width: 100%;
                color: #fff;
                font-size: 18px;
                background: rgba(0, 0, 0, 0.6);
              }
              a {
                display: inline-block;
                width: 29rem;
                height: 22rem;
                color: #fff;
              }
            }
            .one {
              background: no-repeat url(../../assets/t3.png);
              background-size: cover;
            }
            .two {
              background: no-repeat url(../../assets/t1.png);
              background-size: cover;
            }
          }
        }
        .mix-right {
          display: flex;
          width: 50%;
          margin-top: 5.5rem;
          .right-one,
          .right-two {
            width: 50%;
            height: 220px;
            .name {
              text-align: left;
              font-size: 34px;
              cursor: pointer;
              span {
                font-size: 22px;
                margin-left: 10px;
                font-weight: lighter;
                color: #aaaaaa;
                vertical-align: bottom;
                font-family: "Microsoft Yahei", "Simsun";
              }
            }
          }
          .right-one {
            .image {
              width: 300px;
              height: 220px;
              // background: no-repeat url(../../assets/t2.jpg);
              background-size: cover;
              margin-top: 30px;
              position: relative;
              div {
                position: absolute;
                bottom: 0px;
                left: 0px;
                height: 43px;
                line-height: 43px;
                width: 100%;
                color: #fff;
                font-size: 18px;
                background: rgba(0, 0, 0, 0.6);
              }
              a {
                display: inline-block;
                width: 290px;
                height: 220px;
              }
            }
          }
          .right-two {
            margin-left: 10px;
            .video {
              height: 220px;
              margin-top: 30px;
              background-color: #000;
              position: relative;
              div {
                height: 100%;
                background: no-repeat 50% / contain;
              }
              .video-icon {
                position: absolute;
                top: 31%;
                left: 34%;
                width: 100px;
                height: 100px;
                background: no-repeat url(../../assets/videoplay.png);
                background-size: cover;
              }
            }
          }
        }
      }
      .spe-mix111 {
        width: 1200px;
        margin: 0 auto;
        .spe-mix111-title {
          height: 60px;
          display: flex;
          .title {
            font-size: 34px;
            font-weight: lighter;
          }
          .subtitle {
            font-size: 22px;
            color: #aaa;
            margin-left: 10px;
            margin-top: 10px;
            font-weight: lighter;
            font-family: "Microsoft Yahei", "Simsun";
          }
        }
        .spe-mix111-con {
          margin-top: 20px;
          display: flex;
          gap: 10px;
          overflow: hidden;
          .one {
            width: 420px;
            height: 411px;
            overflow: hidden;
            cursor: pointer;
            .img {
              width: 100%;
              height: 100%;
              background: no-repeat
                url(https://www.ahtpw.com/attachment/wutu/index/video/2.png);
              background-size: cover;
              transition: all 500ms ease-in-out;
              a {
                display: inline-block;
                width: 100%;
                height: 100%;
              }
            }
          }
          .two {
            width: 420px;
            height: 411px;
            overflow: hidden;
            cursor: pointer;
            .img {
              width: 100%;
              height: 100%;
              background: no-repeat
                url(https://www.ahtpw.com/attachment/wutu/index/video/3.png);
              background-size: cover;
              transition: all 500ms ease-in-out;
              a {
                display: inline-block;
                width: 100%;
                height: 100%;
              }
            }
          }
          .three {
            width: 420px;
            height: 411px;
            cursor: pointer;
            overflow: hidden;
            .img {
              width: 100%;
              height: 100%;
              background: no-repeat
                url(https://www.ahtpw.com/attachment/wutu/index/video/4.png);
              background-size: cover;
              transition: all 500ms ease-in-out;
              a {
                display: inline-block;
                width: 100%;
                height: 100%;
              }
            }
          }
          div:hover {
            .img {
              transform: scale(1.05);
              opacity: 0.8;
            }
          }
        }
      }
    }
  }
}
</style>

