<template>
  <div>
    <div class="local-whole desktop-present">
      <div
        class="local-img"
        @mouseenter="stopTimer()"
        @mouseleave="restartTimer()"
      >
        <div
          class="addrImg"
          v-for="(p, LcIndex) in idList"
          :key="p + '_' + LcIndex"
          :style="{ backgroundImage: `url(` + p.ImgURL + `)` }"
          :class="{ active: picIndex == LcIndex }"
          @click="onClickLocal()"
        ></div>
        <div class="local-title">
          <div
            class="lcname"
            :class="{ active: picIndex == lcNameIndex }"
            v-for="(lc, lcNameIndex) in lcNameList"
            :key="lc"
            @click="scrollTo(lcNameIndex)"
          >
            {{ lc }}
          </div>
        </div>
        <!-- <div class="nav-container">
          <div
            v-for="(pic, LcIndex) in idList"
            :key="pic + '_' + LcIndex"
            class="nav-pic"
            :class="{ active: picIndex == LcIndex }"
            @click="scrollTo(LcIndex)"
          ></div>
        </div> -->
      </div>
    </div>
    <div class="local-whole mobile-present">
      <div
        class="local-img"
        @mouseenter="stopTimer()"
        @mouseleave="restartTimer()"
      >
        <a
          class="addrImg"
          v-for="(p, LcIndex) in idList"
          :key="p + '_' + LcIndex"
          :style="{ backgroundImage: `url(` + p.ImgURL + `)` }"
          :class="{ active: picIndex == LcIndex }"
          :href="'local.html'"
          target="blank"
        ></a>
        <div class="local-title">
          <div
            class="lcname"
            :class="{ active: picIndex == lcNameIndex }"
            v-for="(lc, lcNameIndex) in lcNameList"
            :key="lc"
            @click="scrollTo(lcNameIndex)"
          >
            {{ lc }}
          </div>
        </div>
        <div class="nav-container">
          <div
            v-for="(pic, LcIndex) in idList"
            :key="pic + '_' + LcIndex"
            class="nav-pic"
            :class="{ active: picIndex == LcIndex }"
            @click="scrollTo(LcIndex)"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
var timer;
import RD from "@/api/RD";

export default {
  name: "localShow",
  props: ["idList"],
  data: function () {
    return {
      picIndex: 0,
      lcNameList: [],
    };
  },
  created: function () {
    this.reload();
  },
  mounted() {
    timer = setInterval(this.next, 5000);
  },
  methods: {
    reload() {
      const thiz = this;
      console.log("lc", thiz.idList);
      setTimeout(() => {
        thiz.idList.forEach((k) => {
          k.ImgURL =
            RD.addr().id(k.id).bgImage_URL() + "?_" + new Date().getTime();
          thiz.lcNameList.push(k.name);
        });
      }, 1000);
    },
    next() {
      this.picIndex++;
      if (this.picIndex == this.idList.length) {
        this.picIndex = 0;
      }
    },
    scrollTo(index) {
      this.picIndex = index;
    },
    stopTimer() {
      clearInterval(timer);
    },
    restartTimer() {
      timer = setInterval(this.next, 3000);
    },
    // 进入城市图库
    onClickLocal() {
      const thiz = this;
      var id = thiz.idList[this.picIndex].id;
      var name = thiz.idList[this.picIndex].name;
      this.$router.push({
        path: "/local.html",
        query: { id: id, name: name },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.local-whole.desktop-present {
  width: 120rem;
  height: 52rem;
  overflow: hidden;
  .local-img {
    width: 120rem;
    height: 43rem;
    margin-right: 3rem;
    background: no-repeat 50% / cover;
    position: relative;
    display: flex;
    flex-shrink: 0;
    flex-wrap: wrap;
    .addrImg {
      height: 100%;
      width: 100%;
      background: no-repeat 50% / cover;
      cursor: pointer;
      position: absolute;
      top: 0rem;
      left: 0rem;
      opacity: 0;
      transition: all 500ms ease-in-out;
    }
    .addrImg.active {
      opacity: 1;
    }
    .local-title {
      height: 5rem;
      width: 120rem;
      position: absolute;
      left: 0rem;
      bottom: -6rem;
      display: flex;
      justify-content: flex-start;
      gap: 2rem;
      .lcname {
        height: 3.5rem;
        line-height: 3.5rem;
        font-size: 1.8rem;
        color: #000;
        cursor: pointer;
        padding: 0 10px;
      }
      .lcname.active {
        color: #fff;
        border-radius: 10px;
        background-color: #d42d26;
      }
    }
    .nav-container {
      position: absolute;
      bottom: 4rem;
      left: 0rem;
      margin: 0rem auto;
      width: 20rem;
      height: 3rem;
      display: flex;
      .nav-pic {
        flex-shrink: 0;
        height: 1rem;
        width: 1rem;
        border-radius: 50%;
        border: 0.1rem solid white;
        background-color: rgba(0, 0, 0, 0.3);
        transition: all 500ms ease-in-out;
        margin-left: 1rem;
        margin-right: 1rem;
        cursor: pointer;
      }
      .nav-pic.active {
        background-color: rgb(23, 47, 184);
      }
    }
  }
}
.local-whole.mobile-present {
  width: 750px;
  height: 300px;
  overflow: hidden;
  .local-img {
    width: 750px;
    height: 43rem;
    margin-right: 3rem;
    background: no-repeat 50% / cover;
    position: relative;
    display: flex;
    flex-shrink: 0;
    flex-wrap: wrap;
    .addrImg {
      height: 100%;
      width: 100%;
      background: no-repeat 50% / cover;
      cursor: pointer;
      position: absolute;
      top: 0rem;
      left: 0rem;
      opacity: 0;
      transition: all 500ms ease-in-out;
    }
    .addrImg.active {
      opacity: 1;
    }
    .local-title {
      height: 5rem;
      width: 120rem;
      position: absolute;
      left: 0rem;
      bottom: -6rem;
      display: flex;
      justify-content: flex-start;
      gap: 2rem;
      .lcname {
        height: 3.5rem;
        line-height: 3.5rem;
        font-size: 1.8rem;
        color: #000;
        cursor: pointer;
        padding: 0 10px;
      }
      .lcname.active {
        color: #fff;
        border-radius: 10px;
        background-color: #d42d26;
      }
    }
    .nav-container {
      position: absolute;
      bottom: 4rem;
      left: 0rem;
      margin: 0rem auto;
      width: 20rem;
      height: 3rem;
      display: flex;
      .nav-pic {
        flex-shrink: 0;
        height: 1rem;
        width: 1rem;
        border-radius: 50%;
        border: 0.1rem solid white;
        background-color: rgba(0, 0, 0, 0.3);
        transition: all 500ms ease-in-out;
        margin-left: 1rem;
        margin-right: 1rem;
        cursor: pointer;
      }
      .nav-pic.active {
        background-color: rgb(23, 47, 184);
      }
    }
  }
}
</style>
