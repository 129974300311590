<template>
  <div class="ftv-con">
    <div class="item-title-container-ftv">
      <!-- <div class="item-title" @click="onClickTopic(webItem.id)">
        <div class="orange"></div>
        <div class="title">{{ webItem.title }}</div>
      </div>
      <div class="more" @click="onClickTopic(webItem.id)">更多>></div> -->
      <div class="web-item-title" @click="onClickTopic(webItem.id)">
        {{ webItem.title }}
      </div>
      <div class="item-subtitle">
        <div class="item">{{ content.subTitle }}</div>
        <div class="item-red"></div>
      </div>
      <div class="web-item-more" @click="onClickTopic(webItem.id)">更多>>></div>
    </div>
    <div class="ftv-model-3s" v-if="webItem.model == '3s'">
      <div
        v-for="(pic, contentIndex) in webItem.list"
        :key="contentIndex + 'FTV' + pic.id"
        class="pic"
        :style="{ backgroundImage: `url(` + pic.imgURL + `)` }"
        :class="{ active: content.picIndex == contentIndex }"
        @click="onLookPost(pic.postId)"
      ></div>
      <div class="text">
        <div
          v-for="(pic, navIndex) in webItem.list"
          :key="'ftv_' + pic.id + '_nav' + '_' + navIndex"
          class="nav-pic"
          :class="{ active: content.picIndex == navIndex }"
          @click="scrollTo(index, navIndex)"
        ></div>
      </div>
    </div>

    <!-- 固定栏目其它 -->
    <div :class="'ftv-model-' + webItem.model" v-else>
      <div
        class="post-con"
        v-for="video in webItem.list"
        :key="'ftv_' + video.postId"
        @click="onLookPost(video.postId)"
      >
        <video
          :src="video.videoURL"
          :poster="video.posterURL"
          :ref="'video_' + video.postId"
          @mouseenter="onVideoEnter('video_' + video.postId)"
          @mouseleave="onVideoLeave('video_' + video.postId)"
          loop
          :muted="video.mute"
        ></video>
        <i
          class="mute el-icon-headset active"
          v-if="video.mute"
          @mouseenter="onVideoEnter('video_' + video.postId)"
          @mouseleave="onVideoLeave('video_' + video.postId)"
          @click.stop="video.mute = !video.mute"
        ></i>
        <i
          class="mute el-icon-headset"
          v-if="!video.mute"
          @mouseenter="onVideoEnter('video_' + video.postId)"
          @mouseleave="onVideoLeave('video_' + video.postId)"
          @click.stop="video.mute = !video.mute"
        ></i>
        <div class="video-text">
          {{ video.title }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RD from "@/api/RD";

export default {
  name: "IndexFtv",
  components: {},
  props: {
    content: {
      type: Object,
      default: function () {
        return {};
      },
    },
    logined: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
  },
  data: function () {
    return {
      webItem: {}, // 视频处理后的数据data
    };
  },
  created() {
    this.reload();
  },
  methods: {
    reload() {
      var thiz = this;
      setTimeout(function () {
        thiz.content.list.forEach((i) => {
          if (i.id) {
            i.posterURL = RD.video().id(i.postId).poster_URL();
          }
          i.posterURL = RD.video().id(i.postId).poster_URL();
          i.videoURL = RD.video().id(i.postId).svideo_URL();
          i.mute = true;
        });
        thiz.webItem = JSON.parse(JSON.stringify(thiz.content));
      }, 2000);
    },
    // 进入播放
    onVideoEnter(ref) {
      this.$refs[ref][0].play();
    },
    // 离开停止
    onVideoLeave(ref) {
      this.$refs[ref][0].pause();
    },
    onClickTopic(topicId) {
      this.$router.push({
        path: "/fixTopic.html",
        query: { id: topicId },
      });
    },
    // 查看稿件
    onLookPost(postId) {
      this.$router.push({
        path: "/post.html",
        query: { id: postId },
      });
    },
  },
};
</script>

<style lang="less" scoped>
@media all and (max-width: 800px) {
  .ftv-con {
    width: 100%;
    margin: 0rem auto;
    position: relative;
    .item-title-container-ftv {
      position: relative;
      display: flex;
      .web-item-title {
        text-align: left;
        margin-top: 18px;
        width: auto;
        height: 4rem;
        line-height: 3.4rem;
        font-size: 34px;
        font-weight: lighter;
        cursor: pointer;
      }
      .item-subtitle {
        font-size: 22px;
        color: #aaa;
        margin-left: 10px;
        font-weight: lighter;
        font-family: "Microsoft Yahei", "Simsun";
      }
      .web-item-more {
        position: absolute;
        color: #878787;
        cursor: pointer;
        right: 1.5rem;
        top: 2.5rem;
        font-size: 1.6rem;
      }
    }

    .post-con:hover {
      .post-img {
        transform: scale(1.05);
      }

      .post-text {
        background-color: rgba(0, 0, 0, 0.4);
        width: 92%;
      }
    }

    .post-con {
      video {
        width: 100%;
        height: 100%;
        object-fit: contain;
        background-color: black;
        position: relative;
        cursor: pointer;
      }

      .mute {
        display: block;
        position: absolute;
        right: 2rem;
        top: 2rem;
        font-size: 2rem;
        height: 3rem;
        width: 3rem;
        border: 2px solid white;
        border-radius: 2rem;
        line-height: 3rem;
        color: white;
      }

      .mute.active {
        color: red;
      }

      .rd-img {
        width: 100%;
        height: 100%;
        background: no-repeat 50% / cover;
        cursor: pointer;
        position: absolute;
        top: 0px;
        left: 0px;
      }

      .video-text {
        position: absolute;
        bottom: 0px;
        width: 100%;
        height: 4.2rem;
        line-height: 4.2rem;
        color: #fff;
        font-size: 1.5rem;
        background-color: rgba(0, 0, 0, 0.3);
      }
    }

    .ftv-model-13 {
      height: 51rem;
      position: relative;
      overflow: hidden;
      width: 120rem;
      margin: 0 auto;

      .post-con {
        position: absolute;
        overflow: hidden;

        video {
          height: 100%;
          width: 100%;
          background-color: black;
          object-fit: contain;
        }
      }

      .post-con:nth-child(1) {
        width: 85rem;
        height: 51rem;
        top: 0;
        left: 0;
      }

      .post-con:nth-child(2) {
        width: 34rem;
        height: 16.2rem;
        top: 0;
        right: 0;
      }

      .post-con:nth-child(3) {
        width: 34rem;
        height: 16.2rem;
        bottom: 17.3rem;
        right: 0;
      }

      .post-con:nth-child(4) {
        width: 34rem;
        height: 16.2rem;
        bottom: 0;
        right: 0;
      }

      .post-con:nth-child(1n + 5) {
        display: none;
      }
    }

    .ftv-model-m_22 {
      height: 60rem;
      position: relative;
      overflow: hidden;
      width: 750px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .post-con {
        width: 370px;
        height: 28rem;
        margin-bottom: 2.8rem;
        position: relative;
      }

      // .post-con:nth-child(1) {
      //   width: 34rem;
      //   height: 25rem;
      //   top: 0;
      //   left: 0;
      // }

      // .post-con:nth-child(2) {
      //   width: 34rem;
      //   height: 25rem;
      //   bottom: 0;
      //   left: 0;
      // }

      // .post-con:nth-child(3) {
      //   width: 85rem;
      //   height: 51rem;
      //   top: 0;
      //   right: 0;
      // }

      .post-con:nth-child(1n + 5) {
        display: none;
      }
    }

    .ftv-model-1111 {
      height: 19.5rem;
      position: relative;
      overflow: hidden;
      width: 120rem;
      margin: 0 auto;

      display: flex;
      justify-content: space-between;

      .post-con {
        width: 29.5rem;
        height: 20rem;
        position: relative;
        overflow: hidden;

        video {
          height: 100%;
          width: 100%;
          background-color: black;
          object-fit: contain;
        }
      }

      .post-con:nth-child(1n + 5) {
        display: none;
      }
    }

    .ft-model-4s {
      height: 20rem;
      position: relative;
      overflow: hidden;
      width: 120rem;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;

      .post-con {
        width: 29.5rem;
        height: 20rem;
        position: relative;
        overflow: hidden;
      }

      .post-con:nth-child(1n + 5) {
        display: none;
      }
    }

    .ft-model-3s {
      height: 79rem;
      position: relative;

      .pic {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        opacity: 0;
        background: no-repeat 50% / cover;
        transition: all 500ms ease-in-out;
        cursor: pointer;
        z-index: 100;
      }

      .pic.active {
        opacity: 1;
        z-index: 200;
      }

      .text {
        display: flex;
        margin: 1.25rem auto;
        position: absolute;
        bottom: 0px;
        width: 100%;
        justify-content: center;
        z-index: 300;

        .nav-pic {
          height: 1.25rem;
          width: 1.25rem;
          border-radius: 0.66rem;
          border: 1px solid rgba(255, 255, 255, 0.4);
          margin-right: 0.85rem;
          background: rgba(0, 0, 0, 0.5);
          transition: all 500ms ease-in-out;
          cursor: pointer;
        }

        .nav-pic.active {
          background-color: rgb(23, 47, 184);
          width: 3.75rem;
        }
      }
    }

    .ft-model-21 {
      height: 51rem;
      position: relative;
      overflow: hidden;
      width: 120rem;
      margin: 0 auto;

      .post-con {
        position: absolute;
        overflow: hidden;
      }

      .post-con:nth-child(1) {
        width: 34rem;
        height: 25rem;
        top: 0;
        left: 0;
      }

      .post-con:nth-child(2) {
        width: 34rem;
        height: 25rem;
        bottom: 0;
        left: 0;
      }

      .post-con:nth-child(3) {
        width: 85rem;
        height: 51rem;
        top: 0;
        right: 0;
      }

      .post-con:nth-child(1n + 4) {
        display: none;
      }
    }
    .ftv-model-12 {
      height: 51rem;
      position: relative;
      overflow: hidden;
      width: 120rem;
      margin: 0 auto;

      .post-con {
        position: absolute;
        overflow: hidden;

        video {
          height: 100%;
          width: 100%;
          background-color: black;
          object-fit: contain;
        }
      }

      .post-con:nth-child(1) {
        width: 85rem;
        height: 51rem;
        top: 0;
        left: 0;
      }

      .post-con:nth-child(2) {
        width: 34rem;
        height: 25rem;
        top: 0;
        right: 0;
      }

      .post-con:nth-child(3) {
        width: 34rem;
        height: 25rem;
        bottom: 0rem;
        right: 0;
      }

      .post-con:nth-child(1n + 4) {
        display: none;
      }
    }

    .ft-model-333 {
      height: 42rem;
      position: relative;
      overflow: hidden;
      width: 120rem;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 2rem;

      .post-con {
        min-width: 29.5rem;
        flex: 1;
        height: 20rem;
        position: relative;
        overflow: hidden;
      }

      .post-con:nth-child(1n + 7) {
        display: none;
      }
    }

    .ft-model-14 {
      height: 41rem;
      position: relative;
      overflow: hidden;
      width: 120rem;
      margin: 0 auto;

      .post-con {
        position: absolute;
        overflow: hidden;
      }

      .post-con:nth-child(1) {
        width: 62rem;
        height: 41rem;
        top: 0;
        left: 0;
      }

      .post-con:nth-child(2),
      .post-con:nth-child(3),
      .post-con:nth-child(4),
      .post-con:nth-child(5) {
        width: 28rem;
        height: 20rem;
      }

      .post-con:nth-child(2) {
        top: 0;
        right: 29rem;
      }

      .post-con:nth-child(3) {
        bottom: 0;
        right: 29rem;
      }

      .post-con:nth-child(4) {
        top: 0;
        right: 0;
      }

      .post-con:nth-child(5) {
        bottom: 0;
        right: 0;
      }

      .post-con:nth-child(1n + 6) {
        display: none;
      }
    }

    .ft-model-1111 {
      height: 40rem;
      position: relative;
      overflow: hidden;
      width: 120rem;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      gap: 2rem;

      .post-con {
        flex: 1;
        height: 40rem;
        position: relative;
        overflow: hidden;
      }

      .post-con:nth-child(1n + 5) {
        display: none;
      }
    }
  }
}
@media all and (min-width: 801px) {
  .ftv-con {
    width: 100%;
    margin: 0rem auto;
    position: relative;
    .item-title-container-ftv {
      position: relative;
      display: flex;
      height: 7rem;
      width: 120rem;
      margin: 3rem auto;
      line-height: 7rem;
      .web-item-title {
        text-align: left;
        margin-top: 2rem;
        width: auto;
        height: 4rem;
        line-height: 3rem;
        font-size: 34px;
        font-weight: lighter;
        cursor: pointer;
      }
      .item-subtitle {
        font-size: 20px;
        color: #aaa;
        height: 6rem;
        margin-left: 10px;
        font-weight: lighter;
        font-family: "Microsoft Yahei", "Simsun";
        position: relative;
        .item-red {
          position: absolute;
          bottom: 8px;
          left: 50%;
          width: 70%;
          transform: translate(-50%);
          height: 3px;
          background-color: #d42d26;
        }
      }
      .web-item-more {
        position: absolute;
        color: #878787;
        cursor: pointer;
        right: 1.5rem;
        top: 0rem;
        font-size: 1.6rem;
      }
    }

    .post-con:hover {
      .post-img {
        transform: scale(1.05);
      }

      .post-text {
        background-color: rgba(0, 0, 0, 0.4);
        width: 92%;
      }
    }

    .post-con {
      video {
        width: 100%;
        height: 100%;
        object-fit: contain;
        background-color: black;
        position: relative;
        cursor: pointer;
      }

      .mute {
        display: block;
        position: absolute;
        right: 2rem;
        top: 2rem;
        font-size: 2rem;
        height: 3rem;
        width: 3rem;
        border: 2px solid white;
        border-radius: 2rem;
        line-height: 3rem;
        color: white;
      }

      .mute.active {
        color: red;
      }

      .rd-img {
        width: 100%;
        height: 100%;
        background: no-repeat 50% / cover;
        cursor: pointer;
        position: absolute;
        top: 0px;
        left: 0px;
      }

      .video-text {
        position: absolute;
        bottom: 0px;
        width: 100%;
        height: 4.2rem;
        line-height: 4.2rem;
        color: #fff;
        font-size: 1.5rem;
        background-color: rgba(0, 0, 0, 0.3);
      }
    }

    .ftv-model-13 {
      height: 51rem;
      position: relative;
      overflow: hidden;
      width: 120rem;
      margin: 0 auto;

      .post-con {
        position: absolute;
        overflow: hidden;

        video {
          height: 100%;
          width: 100%;
          background-color: black;
          object-fit: contain;
        }
      }

      .post-con:nth-child(1) {
        width: 85rem;
        height: 51rem;
        top: 0;
        left: 0;
      }

      .post-con:nth-child(2) {
        width: 34rem;
        height: 16.2rem;
        top: 0;
        right: 0;
      }

      .post-con:nth-child(3) {
        width: 34rem;
        height: 16.2rem;
        bottom: 17.3rem;
        right: 0;
      }

      .post-con:nth-child(4) {
        width: 34rem;
        height: 16.2rem;
        bottom: 0;
        right: 0;
      }

      .post-con:nth-child(1n + 5) {
        display: none;
      }
    }
    .ftv-model-12 {
      height: 51rem;
      position: relative;
      overflow: hidden;
      width: 120rem;
      margin: 0 auto;

      .post-con {
        position: absolute;
        overflow: hidden;

        video {
          height: 100%;
          width: 100%;
          background-color: black;
          object-fit: contain;
        }
      }

      .post-con:nth-child(1) {
        width: 85rem;
        height: 51rem;
        top: 0;
        left: 0;
      }

      .post-con:nth-child(2) {
        width: 34rem;
        height: 25rem;
        top: 0;
        right: 0;
      }

      .post-con:nth-child(3) {
        width: 34rem;
        height: 25rem;
        bottom: 0rem;
        right: 0;
      }

      .post-con:nth-child(1n + 4) {
        display: none;
      }
    }

    .ftv-model-1111 {
      height: 19.5rem;
      position: relative;
      overflow: hidden;
      width: 120rem;
      margin: 0 auto;

      display: flex;
      justify-content: space-between;

      .post-con {
        width: 29.5rem;
        height: 20rem;
        position: relative;
        overflow: hidden;

        video {
          height: 100%;
          width: 100%;
          background-color: black;
          object-fit: contain;
        }
      }

      .post-con:nth-child(1n + 5) {
        display: none;
      }
    }

    .ftv-model-m_22 {
      height: 59rem;
      position: relative;
      overflow: hidden;
      width: 120rem;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .post-con {
        width: 59rem;
        height: 28rem;
        margin-bottom: 2.3rem;
        position: relative;
        video {
          object-fit: cover;
        }
      }

      // .post-con:nth-child(1) {
      //   width: 34rem;
      //   height: 25rem;
      //   top: 0;
      //   left: 0;
      // }

      // .post-con:nth-child(2) {
      //   width: 34rem;
      //   height: 25rem;
      //   bottom: 0;
      //   left: 0;
      // }

      // .post-con:nth-child(3) {
      //   width: 85rem;
      //   height: 51rem;
      //   top: 0;
      //   right: 0;
      // }

      .post-con:nth-child(1n + 5) {
        display: none;
      }
    }

    .ft-model-4s {
      height: 20rem;
      position: relative;
      overflow: hidden;
      width: 120rem;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;

      .post-con {
        width: 29.5rem;
        height: 20rem;
        position: relative;
        overflow: hidden;
      }

      .post-con:nth-child(1n + 5) {
        display: none;
      }
    }

    .ft-model-3s {
      height: 79rem;
      position: relative;

      .pic {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        opacity: 0;
        background: no-repeat 50% / cover;
        transition: all 500ms ease-in-out;
        cursor: pointer;
        z-index: 100;
      }

      .pic.active {
        opacity: 1;
        z-index: 200;
      }

      .text {
        display: flex;
        margin: 1.25rem auto;
        position: absolute;
        bottom: 0px;
        width: 100%;
        justify-content: center;
        z-index: 300;

        .nav-pic {
          height: 1.25rem;
          width: 1.25rem;
          border-radius: 0.66rem;
          border: 1px solid rgba(255, 255, 255, 0.4);
          margin-right: 0.85rem;
          background: rgba(0, 0, 0, 0.5);
          transition: all 500ms ease-in-out;
          cursor: pointer;
        }

        .nav-pic.active {
          background-color: rgb(23, 47, 184);
          width: 3.75rem;
        }
      }
    }

    .ft-model-21 {
      height: 51rem;
      position: relative;
      overflow: hidden;
      width: 120rem;
      margin: 0 auto;

      .post-con {
        position: absolute;
        overflow: hidden;
      }

      .post-con:nth-child(1) {
        width: 34rem;
        height: 25rem;
        top: 0;
        left: 0;
      }

      .post-con:nth-child(2) {
        width: 34rem;
        height: 25rem;
        bottom: 0;
        left: 0;
      }

      .post-con:nth-child(3) {
        width: 85rem;
        height: 51rem;
        top: 0;
        right: 0;
      }

      .post-con:nth-child(1n + 4) {
        display: none;
      }
    }

    .ft-model-333 {
      height: 42rem;
      position: relative;
      overflow: hidden;
      width: 120rem;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 2rem;

      .post-con {
        min-width: 29.5rem;
        flex: 1;
        height: 20rem;
        position: relative;
        overflow: hidden;
      }

      .post-con:nth-child(1n + 7) {
        display: none;
      }
    }

    .ft-model-14 {
      height: 41rem;
      position: relative;
      overflow: hidden;
      width: 120rem;
      margin: 0 auto;

      .post-con {
        position: absolute;
        overflow: hidden;
      }

      .post-con:nth-child(1) {
        width: 62rem;
        height: 41rem;
        top: 0;
        left: 0;
      }

      .post-con:nth-child(2),
      .post-con:nth-child(3),
      .post-con:nth-child(4),
      .post-con:nth-child(5) {
        width: 28rem;
        height: 20rem;
      }

      .post-con:nth-child(2) {
        top: 0;
        right: 29rem;
      }

      .post-con:nth-child(3) {
        bottom: 0;
        right: 29rem;
      }

      .post-con:nth-child(4) {
        top: 0;
        right: 0;
      }

      .post-con:nth-child(5) {
        bottom: 0;
        right: 0;
      }

      .post-con:nth-child(1n + 6) {
        display: none;
      }
    }

    .ft-model-1111 {
      height: 40rem;
      position: relative;
      overflow: hidden;
      width: 120rem;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      gap: 2rem;

      .post-con {
        flex: 1;
        height: 40rem;
        position: relative;
        overflow: hidden;
      }

      .post-con:nth-child(1n + 5) {
        display: none;
      }
    }
  }
}
</style>

