<template>
  <div class="whole">
    <Nav></Nav>
    <div class="present">
      <template v-for="(content, index) in contents">
        <!-- 轮播 -->
        <div
          :key="content.type + '_' + index"
          v-if="content.type == 'TOP_SHOW'"
          class="web-item front-show"
          @mouseenter="stopTimer(index)"
          @mouseleave="restartTimer(index)"
        >
          <TOPSHOW :content="content" :logined="logined"></TOPSHOW>
        </div>

        <!-- 广告 -->
        <div
          class="web-item"
          :key="content.type + '_' + index"
          v-if="content.type == 'AD'"
        >
          <AD :content="content" :logined="logined"></AD>
        </div>

        <!-- 最新图片 -->
        <div
          class="np"
          :key="content.type + '_' + index"
          v-if="content.type == 'NP'"
        >
          <NP :content="content" :logined="logined"></NP>
        </div>

        <!-- 城市 -->
        <div
          class="web-item"
          :key="content.type + '_' + index"
          v-if="content.type == 'LC'"
        >
          <LC :content="content" :logined="logined"></LC>
        </div>

        <!-- 公告 -->
        <div
          class="gonggao"
          :key="content.type + '_' + index"
          v-if="content.type == 'ANNOUNCE'"
        >
          <ANNOUNCE :content="content" :logined="logined"></ANNOUNCE>
        </div>

        <!-- 版面秀 -->
        <div
          class="reperPic"
          :key="content.type + '_' + index"
          v-if="content.type == 'PFT'"
        >
          <PFT :content="content" :logined="logined"></PFT>
        </div>

        <!-- FTS栏目组 -->
        <div
          class="fixedTopic"
          :key="content.type + '_' + index"
          v-if="content.type == 'FTS'"
        >
          <FTS :content="content" :logined="logined"></FTS>
        </div>

        <!-- 自定义栏目组 多个栏目 -->
        <div
          class="fixedTopic"
          :key="content.type + '_' + index"
          v-if="content.type == 'FTM'"
        >
          <FTM :content="content" :logined="logined"></FTM>
        </div>
        <!-- 自定义栏目组 自定义拼接组合-->
        <div
          class="spe"
          :key="content.type + '_' + index"
          v-if="content.type == 'SPE'"
        >
          <SPE :content="content" :logined="logined"></SPE>
        </div>

        <!-- 固定栏目-->
        <div
          class="web-item ft-con"
          :key="content.type + '_' + index"
          v-if="content.type == 'FT'"
        >
          <FT :content="content" :logined="logined"></FT>
        </div>

        <!-- 视频固定栏目 -->
        <div
          class="web-item ftv-con"
          :key="content.type + '_' + index"
          v-if="content.type == 'FTV'"
        >
          <FTV :content="content" :logined="logined"></FTV>
        </div>

        <!-- IFarme -->
        <div
          class="ifame-container"
          :key="content.type + '_' + index"
          v-if="content.type == 'IF'"
        >
          <IF :content="content" :logined="logined"></IF>
        </div>

        <!-- 友情链接 -->
        <div
          class="link"
          :key="content.type + '_' + index"
          v-if="content.type == 'EX_LINK'"
        >
          <LINK :content="content" :logined="logined"></LINK>
        </div>

        <!-- 签约摄影师 -->
        <div
          class="userShow"
          :key="content.type + '_' + index"
          v-if="content.type == 'US'"
        >
          <US :content="content" :logined="logined"></US>
        </div>
      </template>
    </div>
    <Bottom></Bottom>
  </div>
</template>

<script>
var timers = [];
import RD from "@/api/RD";
import Nav from "@/components/nav.vue";
import Bottom from "@/components/bottom.vue";
import AD from "@/views/index/ad.vue";
import PFT from "@/views/index/pft.vue";
import LINK from "@/views/index/link.vue";
import IF from "@/views/index/ifame.vue";
import TOPSHOW from "@/views/index/topShow.vue";
import ANNOUNCE from "@/views/index/announce.vue";
import FTS from "@/views/index/fts.vue";
import FT from "@/views/index/ft.vue";
import FTV from "@/views/index/ftv.vue";
import FTM from "@/views/index/ftm.vue";
import SPE from "@/views/index/spe.vue";
import LC from "@/views/index/lc.vue";
import NP from "@/views/index/np.vue";
import US from "@/views/index/us.vue";

export default {
  name: "",
  components: {
    Nav,
    Bottom,
    AD,
    PFT,
    LINK,
    IF,
    TOPSHOW,
    ANNOUNCE,
    FTS,
    FT,
    FTM,
    SPE,
    LC,
    NP,
    FTV,
    US,
  },
  data() {
    return {
      contents: [], // 美美吴江的数据
      logined: false, // 登录的状态
    };
  },
  created() {
    this.reload();
  },
  mounted() {},
  methods: {
    reload() {
      var thiz = this;
      RD.index()
        .main(1)
        .then((data) => {
          data.contents.forEach((content, contentIndex) => {
            content.picIndex = 0;
            switch (content.type) {
              case "TOP_SHOW":
                content.list.forEach((i) => {
                  if (i.frontPicId) {
                    i.imgURL = RD.pic().id(i.frontPicId).tImage_URL();
                  }
                });
                timers[contentIndex] = setInterval(() => {
                  this.next(contentIndex);
                }, 3000);
                break;
              case "FT":
              case "TOP_FT":
                if (content.model == "3s") {
                  content.list = content.list.splice(0, 5);
                }
                content.list.forEach((i, index) => {
                  if (i.postId) {
                    i.imgURL = RD.pic().id(i.frontPicId).mImage_URL();
                  }
                });
                if (timers[contentIndex]) {
                  clearInterval(timers[contentIndex]);
                }
                timers[contentIndex] = setInterval(() => {
                  this.next(contentIndex);
                }, thiz.selectGapByType(content.type));
                break;
            }
          });
          thiz.contents = data.contents;
        });
    },
    selectGapByType(type) {
      switch (type) {
        case "TOP_SHOW":
          return 3000;
        default:
          return 3000;
      }
    },
    next(contentIndex) {
      var thiz = this;
      thiz.contents[contentIndex].picIndex++;
      if (
        thiz.contents[contentIndex].picIndex >=
        thiz.contents[contentIndex].list.length
      ) {
        thiz.contents[contentIndex].picIndex = 0;
      }
    },
    stopTimer(contentIndex) {
      // console.log('stopTimer', contentIndex)
      clearInterval(timers[contentIndex]);
    },
    restartTimer(contentIndex) {
      clearInterval(timers[contentIndex]);
      timers[contentIndex] = setInterval(() => {
        this.next(contentIndex);
      }, 2000);
    },
    // 查看公告里面的详情
    onAnnounce(id, type) {
      console.log("kl", id);

      this.$router.push({
        path: "/announceDetail.html",
        query: { id: id, type: type },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.present {
  background-color: #f7f8fa;
}

::v-deep .web-item {
  // height: auto;
  // display: flex;
  margin: 0 auto;
  // width: 100%;
  flex-direction: column;
}
::v-deep .web-item {
  .web-item-header {
    text-align: left;
    display: flex;
    .web-item-icon {
      width: 5px;
      height: 3rem;
      background-color: red;
    }
    // .web-item-title {
    //   font-size: 2rem;
    //   padding-left: 1rem;
    // }
  }
}
@media all and (min-width: 801px) {
  ::v-deep .web-item {
    .web-item-header {
      width: 120rem;
      text-align: left;
      position: relative;
      height: 7rem;
      line-height: 7rem;
      margin-top: 3rem;
      margin-bottom: 3rem;
      .web-item-icon {
        position: absolute;
        width: 5rem;
        height: 5rem;
        left: 0;
        top: 1.4rem;
      }
      .web-item-title {
        text-align: left;
        margin-top: 2rem;
        width: auto;
        height: 4rem;
        line-height: 3.4rem;
        font-size: 34px;
        font-weight: lighter;
        cursor: pointer;
      }
      .item-subtitle {
        font-size: 22px;
        color: #aaa;
        margin-left: 10px;
        font-weight: lighter;
        font-family: "Microsoft Yahei", "Simsun";
      }
      .web-item-more {
        position: absolute;
        color: #878787;
        cursor: pointer;
        right: 1.5rem;
        top: 0.5rem;
        font-size: 1.6rem;
        a {
          color: grey;
          font-weight: lighter;
        }
      }
      .web-item-header-gap {
        position: absolute;
        background: #bfbfbf;
        top: 6.5rem;
        width: 120rem;
        height: 0.2rem;
      }
    }
    .item-title-container {
      height: 10rem;
      width: 120rem;
      margin: 1.7rem auto;
      line-height: 10rem;

      .item-title {
        font-size: 3.5rem;
        height: 7.5rem;
        padding: 0px 1.5rem;
        text-align: left;
      }
    }
  }
}
@media all and (max-width: 800px) {
  .present {
    width: 730px;
    margin: 10px auto;
  }
  ::v-deep .web-item {
    overflow: hidden;
    .item-title-container {
      height: 62px;
      width: 750px;
      margin: 10px auto;
      line-height: 62px;

      .item-title {
        font-size: 14px;
        border-bottom: 3px solid #cf0000;
        height: 45px;
        padding: 0px 10px;
      }
    }
  }
}
</style>